import { DROPDOWN_MENU_TYPE_ITEM, DROPDOWN_MENU_TYPE_HEADER, DROPDOWN_MENU_TYPE_DIVIDER } from 'presentation/model/constants';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function sanitizeContainerProps(rawProps) {
  const DEFAULT_TITLE = 'ações';
  const DEFAULT_BUTTON_VARIANT = 'outline-success';

  var containerProps = rawProps || {};

  if (!containerProps.title) {
    containerProps.title = (<small>{DEFAULT_TITLE}</small>);
  }

  if (!containerProps.variant) {
    containerProps.variant = DEFAULT_BUTTON_VARIANT;
  }

  return containerProps;
}

function DropdownMenu(props) {
  const { containerProps: rawContainerProps, items } = props;

  var containerProps = sanitizeContainerProps(rawContainerProps);

  return (
    <DropdownButton {...containerProps} >
      {items.map((mi, idx) => {

        switch (mi.type) {
          case DROPDOWN_MENU_TYPE_ITEM: { return <Dropdown.Item key={idx} {...mi.props}>{mi.title}</Dropdown.Item> } break;
          case DROPDOWN_MENU_TYPE_HEADER: { return <Dropdown.Header key={idx}>{mi.title}</Dropdown.Header> } break;
          case DROPDOWN_MENU_TYPE_DIVIDER: { return <Dropdown.Divider key={idx} /> } break;
        }
      })}
    </DropdownButton>
  );
}


export default DropdownMenu;