import React from "react";
import { Modal, Button, Table, Form } from "react-bootstrap";
import CancelButton from "presentation/components/buttons/cancel";


import { handleResultData, handleRadioTableItems } from "presentation/utils/functions";

import classroomsStudentsService from "presentation/services/classroom-students";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import { MEDIA_DIRECTORY_STUDENT_ALLOW, MEDIA_DIRECTORY_STUDENT_DENY } from "presentation/model/constants";


class ClassroomStudentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            filterForm: {},
            selectedItems: [],
            data: []
        };

        this.tBodyRef = React.createRef(null);
    }
    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        const { classroomId, disciplineId, component } = this.props;
        let { directoriesPermissions } = component.state;

        await classroomsStudentsService.search({ classroomId: '' + classroomId }).then((result) => {
            let handledResult = handleResultData(result);

            let selectedItems = [];
            if (directoriesPermissions != null && directoriesPermissions.length > 0) {

                selectedItems = directoriesPermissions.filter(x => x.classroomId == classroomId && x.disciplineId == disciplineId);
            } else {
                //por padrão todo mundo tem permissão
                handledResult.map((e, i) => {
                    let studentId = e.student?.id;
                    let selectedItem = {
                        key: `${classroomId}-${disciplineId}-${studentId}`,
                        permissionType: MEDIA_DIRECTORY_STUDENT_ALLOW,
                        studentId: studentId,
                        classroomId: classroomId,
                        disciplineId: disciplineId
                    };
                    selectedItems.push(selectedItem);
                });
            }

            this.setState({ selectedItems: selectedItems, data: handledResult, loading: false });
        });
    }

    loadSelectedOption = (key, searchField, defaultValue) => {
        const { selectedItems } = this.state;
        let output = defaultValue;

        if (selectedItems != null && selectedItems.length > 0) {
            let element = selectedItems.filter(x => x.key == key)[0];

            if (element != null && (typeof (element[searchField]) !== 'undefined')) {
                output = element[searchField];
            }
        }

        return output;
    }

    onSubmit = async () => {
        let { component, onClose, classroomId, disciplineId } = this.props;
        let { selectedItems } = this.state;
        let { directoriesPermissions } = component.state;


        this.setState({ loading: true });

        let oldPermissions = directoriesPermissions.filter(x => !(x.classroomId == classroomId && x.disciplineId == disciplineId));

        let newPermissions = [...selectedItems, ...oldPermissions];

        await component.setState({
            directoriesPermissions: newPermissions
        }, () => {
            onClose();
        });
    };

    render() {
        let { show, onClose, classroomName, classroomId, disciplineId, disciplineName } = this.props;
        let { data, loading, error, message } = this.state;
        const { onSubmit, tBodyRef, loadSelectedOption } = this;
        return (
            <Modal fullscreen backdrop="static" show={show || false} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Diretório {classroomName} / {disciplineName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                    {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                    {loading === false && (
                        <>
                            {data.length > 0 && (
                                <>
                                    <p className="text-center"><strong>Personalize o acesso dos alunos ao material</strong></p>
                                    <Table className="mt-4">
                                        <thead>
                                            <tr>
                                                <th rowSpan={2}>Matrícula</th>
                                                <th rowSpan={2}>Nome</th>
                                                <th className="text-center" colSpan={2}>Acesso</th>
                                            </tr>
                                            <tr>
                                                <th className="text-center">Permitir</th>
                                                <th className="text-center">Negar</th>
                                            </tr>
                                        </thead>
                                        <tbody ref={tBodyRef}>
                                            {data.length > 0 && data.map((element, index) => {
                                                let studentId = element.student?.id;
                                                let extraFields = { studentId: studentId, classroomId: classroomId, disciplineId: disciplineId };
                                                let radioKey = `${classroomId}-${disciplineId}-${studentId}`;

                                                let selectedOption = loadSelectedOption(radioKey, 'permissionType', MEDIA_DIRECTORY_STUDENT_ALLOW);
                                                return (
                                                    <tr key={element.student?.id}>
                                                        <td>{element.student?.registration}</td>
                                                        <td>{element.student?.person?.name}</td>
                                                        <td className="text-center"><Form.Check className="row-checkbox" type="radio" checked={selectedOption == MEDIA_DIRECTORY_STUDENT_ALLOW} name={`access-option-student-${element.id}`} onChange={(e) => handleRadioTableItems(radioKey, 'permissionType', MEDIA_DIRECTORY_STUDENT_ALLOW, extraFields, this)} /></td>
                                                        <td className="text-center"><Form.Check className="row-checkbox" type="radio" checked={selectedOption == MEDIA_DIRECTORY_STUDENT_DENY} name={`access-option-student-${element.id}`} onChange={(e) => handleRadioTableItems(radioKey, 'permissionType', MEDIA_DIRECTORY_STUDENT_DENY, extraFields, this)} /></td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton
                        containerProps={{
                            disabled: loading,
                            onClick: onClose
                        }}
                    />
                    <Button disabled={loading} variant="success" onClick={() => onSubmit()}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
}

export default ClassroomStudentModal;