import React from "react";
import { Link } from "react-router-dom";
import { Form, Button, ButtonGroup, Container, Row, Col, Breadcrumb, Fade, InputGroup, CardGroup, Card } from "react-bootstrap";
import parse from 'html-react-parser';
import PageAlert from "presentation/components/page-alert";
import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import { goTo, isNullOrEmpty } from "presentation/utils/functions";

import activityAnswersService from "presentation/services/activity-answers";


class ManualFillForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            localLoading: false,
            localError: false,
            message: '',
            studentActiveKey: 0,
        }

    }

    setActive = (key) => {
        this.setState({ activeKey: parseInt(key) });
    }

    isActive = (itemKey) => {
        const { activeKey } = this.state;
        let response = itemKey == activeKey;
        return response;
    };

    setActiveStudent = (key) => {
        this.setState({ studentActiveKey: parseInt(key) });
    }

    isStudentActive = (itemKey) => {
        const { studentActiveKey } = this.state;
        let response = itemKey == studentActiveKey;
        return response;
    };

    setAnswer = (event, studentId, question, rawQuestionOptionId, observations = "") => {
        const { loadQuestionAnswer } = this;
        const { component } = this.props;
        const { answers } = component.state;
        var questionId = question.id;

        let answerItem = loadQuestionAnswer(questionId, studentId);
        let questionOptionId = parseInt(rawQuestionOptionId);


        let isChecked = event.target.checked;

        if (answerItem) {
            let allowMultiple = question.allowMultipleOptionsAnswers;

            if (allowMultiple == true) {
                if (!answerItem.questionOptionId.includes(questionOptionId) && isChecked == true) {
                    answerItem.questionOptionId.push(questionOptionId);
                } else {
                    let currentOptions = answerItem.questionOptionId.filter(x => x !== questionOptionId);
                    answerItem.questionOptionId = currentOptions;
                }
            } else {
                answerItem.questionOptionId = [questionOptionId];
            }


            answerItem.observations = observations;
        } else {
            answerItem = { questionId: questionId, studentId: studentId, questionOptionId: [questionOptionId], observations: observations };
        }

        let filteredAnswers = answers.filter(x => !((x.questionId == answerItem.questionId) && (x.studentId == answerItem.studentId)));
        let addAnswer = true;

        if (question.questionTypeId == 2 && (answerItem.observations.trim().length === 0)) {
            addAnswer = false;
        }

        if (answerItem.questionOptionId.length <= 0) {
            addAnswer = false;
        }

        if (addAnswer) {
            filteredAnswers.push(answerItem);
        }


        component.setState({ answers: filteredAnswers });

    }

    loadQuestionAnswer = (questionId, studentId) => {
        const { component } = this.props;
        const { answers } = component.state;
        let answerItem = answers.filter(x => x.questionId == questionId && x.studentId == studentId)[0];
        return answerItem ? answerItem : undefined;
    }


    getAnswer = (questionId, studentId) => {
        const { loadQuestionAnswer } = this;

        let answerItem = loadQuestionAnswer(questionId, studentId);
        return answerItem ? answerItem : undefined;

    };

    onSubmit = async () => {
        const { component, returnTo } = this.props;
        const { answers, rawAnswers, activity } = component.state;

        component.setState({ loading: true });
        var requests = undefined;
        var postData = [];
        if (answers.length > 0) {
            answers.map((e, i) => {
                if (e.questionOptionId) {
                    e.questionOptionId.map((o, j) => {
                        let answer = {
                            questionOptionId: o,
                            questionId: e.questionId,
                            observations: e.observations,
                            classroomId: activity.classroom.id,
                            disciplineId: activity.discipline.id,
                            studentId: e.studentId,
                            activityId: activity.id,
                        };

                        postData.push(answer);
                    });
                }
            });
        }

        //To Delete
        var deleteRequests = [];
        var deleteData = rawAnswers.map(async (re, rid) => {

            let stillExisting = postData.filter(x => x.studentId == re.studentId && x.questionId == re.questionId && x.questionOptionId == re.questionOptionId)[0];

            if (!stillExisting) {
                deleteRequests.push(await activityAnswersService.remove(re.id));
            }
        });

        Promise.all(deleteRequests);


        if (postData.length > 0) {
            requests = [];
            postData.map(async (r, i) => {
                requests.push(await activityAnswersService.add(r));
            });
            if (Promise.all(requests)) {
                goTo(returnTo, component);
            } else {
                component.setState({ loading: false, error: true, message: 'Erro ao enviar as respostas... Por favor, tente novamente mais tarde' });
            }

        } else {
            component.setState({ loading: false, error: true, message: 'Erro ao registrar/remover as respostas... Por favor, tente novamente mais tarde' });
        }

    };

    render() {

        let { component, returnTo } = this.props;

        const { loading, activity, questions: rawQuestions, students: rawStudents, answers, rawAnswers } = component.state;

        const questions = rawQuestions.sort((a, b) => a.order - b.order);
        const students = rawStudents.sort((a, b) => a.order - b.order);

        let { localError, localLoading, message, studentActiveKey } = this.state;

        const { isStudentActive, setActiveStudent, getAnswer, setAnswer, onSubmit } = this;
        const totalStudents = students.length;


        return (
            <>
                {loading === true || localLoading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                {(localLoading === false && localError === true) && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {(localLoading === false && localLoading === false && questions.length <= 0) && <PageAlert className="m-b-20" message={"Erro"} description={"A atividade selecionada não tem questões para responder..."} type="danger" />}
                {loading === false && localLoading === false && questions.length >= 0 && (
                    <Form>
                        <Container className="pb-5">
                            <Breadcrumb className="site-breadcrumb">
                                <Breadcrumb.Item active>
                                    <span className="mt-1">{activity.classroom.displayName}</span>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    <span className="mt-1">{activity.discipline.displayName}</span>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    <span className="mt-1">{activity.title}</span>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            <Row className="m-b-4 activity-options-container">
                                {students.map((s, j) => {
                                    var isStudentActiveItem = isStudentActive(j);
                                    return (
                                        <div key={s.studentId}>
                                            <Fade in={isStudentActiveItem}>
                                                <div>
                                                    {isStudentActiveItem == true && (
                                                        <div>
                                                            <p className="text-muted">Aluno: <strong>{s.studentName}</strong></p>
                                                            {questions.map((e, i) => {
                                                                var options = e.question.questionOptions;
                                                                var firstOption = options[0];
                                                                var questionType = e.question.questionTypeId;
                                                                var allowMultipleAnswers = e.question.allowMultipleOptionsAnswers;
                                                                var questionAnswer = getAnswer(e.question.id, s.studentId);

                                                                return (
                                                                    <div key={e.id} className="activity-answers-view mb-4">
                                                                        <div>
                                                                            <hr className="mb-3" />
                                                                            <h6>Questão {i + 1}</h6>
                                                                            <div className="mb-4">
                                                                                {parse(e.question.title)}
                                                                            </div>
                                                                            {questionType == 1 && allowMultipleAnswers == false && options.map((o, j) => {
                                                                                return (
                                                                                    <div key={`opt-${o.id}`}>
                                                                                        <Form.Check
                                                                                            type="radio"
                                                                                            label={parse(o.title)}
                                                                                            name={`opt-${o.questionId}`}
                                                                                            value={o.id}
                                                                                            checked={questionAnswer ? questionAnswer.questionOptionId.includes(o.id) : false}
                                                                                            onChange={(ev) => setAnswer(ev, s.studentId, e.question, ev.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                            {questionType == 1 && allowMultipleAnswers == true && options.map((o, j) => {
                                                                                return (
                                                                                    <div key={`opt-${o.id}`}>
                                                                                        <Form.Check
                                                                                            type="checkbox"
                                                                                            label={parse(o.title)}
                                                                                            name={`opt-${o.questionId}-item${o.id}`}
                                                                                            value={o.id}
                                                                                            checked={questionAnswer ? questionAnswer.questionOptionId.includes(o.id) : false}
                                                                                            onChange={(ev) => setAnswer(ev, s.studentId, e.question, ev.target.value)}
                                                                                        />
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                            {questionType == 2 && (
                                                                                <div key={`opt-${firstOption.id}`}>
                                                                                    <Form.Control
                                                                                        className="activity-answer-observations"
                                                                                        as={'textarea'}
                                                                                        name={`opt-${firstOption.questionId}`}
                                                                                        value={questionAnswer ? questionAnswer.observations : undefined}
                                                                                        onChange={(ev) => setAnswer(ev, s.studentId, e.question, firstOption.id, ev.target.value)}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    )}
                                                </div>
                                            </Fade>
                                        </div>
                                    );
                                })}
                            </Row>
                        </Container>
                        <Container fluid className="align-middle activity-navigation">
                            <Row>
                                <Col>
                                    <Container>
                                        <Row>
                                            <Col>
                                                <Button size="sm" as={Link} to={returnTo || "/"} variant="secondary" className="float-start m-r-1 mt-4">Sair</Button>
                                                <Button size="sm" variant="success" className="float-start m-r-1 mt-4" disabled={studentActiveKey == 0} onClick={() => setActiveStudent(studentActiveKey - 1)}> Anterior</Button>
                                                <Form.Select size="sm" className="activity-select-question float-start m-r-1 mt-4" value={studentActiveKey} onChange={(e) => setActiveStudent(e.target.value)}>
                                                    {students.map((s, j) => {
                                                        return <option key={s.studentId} value={j}>{`${s.studentName}`}</option>
                                                    })}
                                                </Form.Select>
                                                <Button size="sm" variant="success" className="float-start mt-4" disabled={studentActiveKey == (totalStudents - 1)} onClick={() => setActiveStudent(studentActiveKey + 1)} >Próximo</Button>
                                                <Button variant="success" size="sm" className="float-end mt-4" onClick={onSubmit}>Finalizar</Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                )
                }
            </>
        );
    };
}

export default ManualFillForm;