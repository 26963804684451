import React from "react";
import { Modal, Button, Form, Container, Row, Col } from "react-bootstrap";

import { saveAs } from 'file-saver';
import { renderToString } from 'react-dom/server';

import PageAlert from "presentation/components/page-alert";
import ModalLoadingIndicator from "presentation/components/modal-loading-indicator";
import CancelButton from "presentation/components/buttons/cancel";


import { handleChange } from "presentation/utils/functions";

import activitiesService from "presentation/services/activities";
import activitiesQuestionsService from "presentation/services/activity-questions";
import dataHandlerService from "presentation/services/data-handler";

import AnswersSheetExportStructureContainer from 'presentation/pages/activities/elements/answers-sheet-export-structure-container';

import { activityExportModes as exportModes, ACTIVITY_EXPORT_ANSWERS_SHEET_AT_END } from 'presentation/model/constants';


class ActivityExportModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            message: '',
            form: { exportMode: ACTIVITY_EXPORT_ANSWERS_SHEET_AT_END }
        };
    }

    async componentDidMount() {
        const { activityId } = this.props;
        if (activityId) {
            await this.loadData(activityId);
        } else {
            this.setState({ error: true, loading: false, message: "Não foi possível identificar a atividade" });
        }

    }


    loadData = async (rawId) => {

        var activityId = parseInt(rawId);
        var actityResults = await activitiesService.findById(activityId);
        var actityQuestionsResults = await activitiesQuestionsService.search({ activityId: [activityId] });
        await Promise.all([actityResults, actityQuestionsResults]);

        this.setState({ activity: actityResults, questions: actityQuestionsResults, loading: false });
    }

    onSubmit = async () => {
        let { activity, questions, form } = this.state;
        this.setState({ loading: true });
        var html = renderToString(<AnswersSheetExportStructureContainer
            activity={activity}
            questions={questions}
            exportMode={form.exportMode}
        />);
        var response = await dataHandlerService.HTMLtoDOCX({ requestContent: html });
        if (response.error === true) {
            this.setState({ error: true, message: 'Erro ao gerar arquivo para exportação. Por favor, verifique se todas as questões dessa atividade são válidas...' });
        } else {
            var blob = new Blob([response]);
            saveAs(blob, 'export.docx');
        }


        // const url = window.URL.createObjectURL(new Blob([response]));
        // const a = document.createElement("a");
        // a.href = url;
        // a.download = "export.docx";
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);
        // window.URL.revokeObjectURL(url);

        this.setState({ loading: false });
    };


    render() {
        let { show, onClose } = this.props;

        let { loading, error, message, form } = this.state;
        const { onSubmit } = this;

        const component = this;



        return (
            <Modal backdrop="static" show={show || false} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Exportar atividade</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading === true && <ModalLoadingIndicator loadingText={"Carregando informações..."} />}
                    {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                    {loading === false && (
                        <Form>
                            <Container>
                                <Row>
                                    <Col size={12}>
                                        <div className="form mt-2">
                                            <Form.Label htmlFor={`exportMode`}>Opções de exportação</Form.Label>
                                            <Form.Select
                                                aria-label="Opções de exportação"
                                                id={`exportMode`}
                                                name={`exportMode`}
                                                data-field-name="exportMode"
                                                defaultValue={form.exportMode}
                                                onChange={(e) => handleChange(e, component)}
                                                aria-describedby={`exportModeHelpBlock`}
                                            >
                                                {exportModes.map((e, i) => { return <option key={e.id} value={e.id}>{e.name}</option> })}
                                            </Form.Select>
                                            <Form.Text id={`exportModeHelpBlock`} muted>
                                                Defina como gostaria de exportar a atividade
                                            </Form.Text>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <CancelButton
                        containerProps={{
                            disabled: loading,
                            onClick: onClose
                        }}
                    />
                    <Button disabled={loading} variant="success" onClick={() => onSubmit()}>
                        Exportar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
}

export default ActivityExportModal;