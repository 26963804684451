import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import { loadStudentData } from 'presentation/pages/principal/dashboards/students/functions/students';

import { getUser } from 'presentation/utils/authentication';

import StudentDashboardContent from "presentation/pages/principal/dashboards/students/elements/student-dashboard-content";

class ResponsibleDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            actionLoading: false,
            error: false,
            message: '',
            dependents: [],
            selectedDependent: null,
            person: { name: undefined, displayName: undefined },
            studentData: { data: [] }
        };
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        const { selectedDependent } = this.state;

        this.setState({ loading: true });
        const userInfo = getUser();

        if (userInfo.responsibleInfo != null && userInfo.responsibleInfo.dependents && userInfo.responsibleInfo.dependents.length > 0) {

            var dependents = userInfo.responsibleInfo.dependents;

            var studentId = dependents[0].id;

            if (selectedDependent) {
                studentId = selectedDependent;
            }



            var selectedStudent = dependents.filter(x => x.id == studentId)[0];

            var studentPerson = selectedStudent ? selectedStudent.person : { name: undefined, displayName: undefined };

            var studentData = await loadStudentData(studentId);
            studentData.person = studentPerson;

            this.setState({ studentData: studentData, loading: false, dependents: dependents, selectedDependent: studentId });
        }

    }

    changeDependent = (e) => {
        var selectedValue = e.target.value;
        this.setState({ selectedDependent: selectedValue }, () => {
            this.loadData();
        });
    }

    render() {


        const {
            loading,
            actionLoading,
            error,
            message,
            selectedDependent,
            dependents,
            studentData
        } = this.state;
        const { changeDependent } = this;
        return (
            <div>
                {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
                {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {loading === false && error === false && studentData.data.length <= 0 && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />}
                {loading === false && (
                    <div>
                        <Container className="mb-3">
                            <Row>
                                <Col>
                                    <h4>Visualizando informações do aluno</h4>
                                </Col>
                                <Col>
                                    <form>
                                        <Form.Select
                                            aria-label="Aluno"
                                            defaultValue={selectedDependent}
                                            id="selectedDependent"
                                            name="selectedDependent"
                                            onChange={changeDependent}
                                            aria-describedby="documentFieldTypeHelpBlock"
                                        >
                                            {dependents.map((e, i) => { return <option key={e.id} value={e.id}>{e.person?.name}</option> })}
                                        </Form.Select>
                                    </form>
                                </Col>
                            </Row>
                        </Container>
                        <StudentDashboardContent contentProps={studentData} />
                    </div>
                )}
            </div>
        );
    };
}

export default ResponsibleDashboard;