import React from 'react';
import { Filter } from 'react-bootstrap-icons';
import { Button } from "react-bootstrap";

function sanitize(rawProps) {
  const DEFAULT_BUTTON_VARIANT = 'success';
  const DEFAULT_BUTTON_CLASSNAME = 'float-end';

  var props = rawProps || {};

  if (!props.variant) {
    props.variant = DEFAULT_BUTTON_VARIANT;
  }

  if (!props.className) {
    props.className = DEFAULT_BUTTON_CLASSNAME;
  }

  return props;
}

function FilterButton(props) {
  const { containerProps: rawProps, title } = props;
  const DEFAULT_BUTTON_TITLE = 'Filtrar';

  var buttonProps = sanitize(rawProps);

  return (
    <Button {...buttonProps}><Filter /> {title || DEFAULT_BUTTON_TITLE}</Button>
  );
}


export default FilterButton;