import React from "react";
import { Container, Row, Breadcrumb } from "react-bootstrap";
import parse from 'html-react-parser';

import { mountAnswersSheet, alphanumericIndex } from "presentation/pages/activities/functions/activities";
import { ACTIVITY_EXPORT_ANSWERS_AFTER_QUESTION, ACTIVITY_EXPORT_ANSWERS_SHEET_ONLY, ACTIVITY_EXPORT_ANSWERS_SHEET_AT_END, ACTIVITY_EXPORT_QUESTIONS_ONLY } from 'presentation/model/constants';

class AnswersSheetExportStructure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            localLoading: false,
            localError: false,
            message: ''
        }

    }

    getQuestionTypeLabel = (questionType, allowMultipleAnswers) => {


        if (questionType == 1) {
            if (allowMultipleAnswers) {
                return "resposta múltipla";
            } else {
                return "resposta única";
            }
        }

        if (questionType == 2) {
            return "dissertativa";
        }

        return "";
    };

    render() {

        let { questions: rawQuestions, activity, exportMode } = this.props;

        const questions = rawQuestions.sort((a, b) => a.order - b.order);

        const { getQuestionTypeLabel } = this;

        let answersSheet = mountAnswersSheet(questions);

        return (
            <>
                {questions.length >= 0 && (
                    <Container className="pb-5">
                        <div>
                            <strong>Turma: {activity.classroom.displayName}</strong> /
                            <strong>Disciplina: {activity.discipline.displayName}</strong> /
                            <strong>Atividade: {activity.title}</strong>
                        </div>
                        <Row className="activity-options-container">
                            {exportMode != ACTIVITY_EXPORT_ANSWERS_SHEET_ONLY && questions.map((e, i, allElements) => {

                                var options = e.question.questionOptions.sort((a, b) => a.orderNumber - b.orderNumber);
                                var questionType = e.question.questionTypeId;
                                var allowMultipleAnswers = e.question.allowMultipleOptionsAnswers;
                                var questionAnswerSheet = answersSheet.filter(x => x.questionId == e.question.id);

                                var questionTypeLabel = getQuestionTypeLabel(questionType, allowMultipleAnswers);

                                var nextIndex = i + 1;
                                var nextElement = allElements[nextIndex];
                                var questionNumber = i + 1;
                                return (
                                    <div key={e.id}>
                                        <div>

                                            <h6>Questão {questionNumber} ({questionTypeLabel})</h6>
                                            <div className="mb-4">
                                                {parse(e.question.title)}
                                            </div>
                                            {questionType == 1 && (
                                                <table>


                                                    {options.map((o, j) => {

                                                        var alphaOrderNumber = alphanumericIndex(o.orderNumber);
                                                        return (
                                                            <tr key={`opt-${o.id}`}>
                                                                <td width="6%">{alphaOrderNumber})</td>
                                                                <td>{parse("" + o.title)}</td>
                                                            </tr>

                                                        );
                                                    })}
                                                </table>
                                            )}
                                            {questionType == 2 && (
                                                <>
                                                    <h6>Sua resposta:</h6>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                </>
                                            )}
                                            {exportMode == ACTIVITY_EXPORT_ANSWERS_AFTER_QUESTION && (
                                                <>
                                                    <h6>Gabarito</h6>
                                                    {questionAnswerSheet.map((e, idx) => {
                                                        var questionResolution = e.questionResolution;
                                                        return (
                                                            <div key={idx}>
                                                                {e.correctAnswers.map((optItem, jdx) => {
                                                                    return (
                                                                        <p key={jdx}>{e.questionType == 1 ? `[${optItem.alphaOrderNumber}]` : parse(optItem.optionValue)}</p>
                                                                    )
                                                                })}
                                                                {questionResolution && (
                                                                    <>
                                                                        <h6>Resolução</h6>
                                                                        {parse(questionResolution)}
                                                                    </>
                                                                )}
                                                            </div>
                                                        )
                                                    })}
                                                </>

                                            )}
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            {(nextElement || (!nextElement && exportMode == ACTIVITY_EXPORT_ANSWERS_SHEET_AT_END)) && (<hr />)}
                                        </div>
                                    </div>
                                )
                            })}
                            {(exportMode == ACTIVITY_EXPORT_ANSWERS_SHEET_ONLY || exportMode == ACTIVITY_EXPORT_ANSWERS_SHEET_AT_END) && (

                                <>
                                    <h6>Gabarito</h6>
                                    {
                                        questions.map((e, i) => {


                                            var questionAnswerSheet = answersSheet.filter(x => x.questionId == e.question.id);

                                            return (
                                                <div key={e.id} className="activity-answers-view">
                                                    <div>

                                                        <strong>Questão {i + 1}</strong>
                                                        {questionAnswerSheet.map((e, idx) => {
                                                            var questionResolution = e.questionResolution;
                                                            return (
                                                                <div key={idx}>
                                                                    {e.correctAnswers.map((optItem, jdx) => {
                                                                        return (
                                                                            <p key={jdx}>{e.questionType == 1 ? `[${optItem.alphaOrderNumber}]` : parse(optItem.optionValue)}</p>
                                                                        )
                                                                    })}
                                                                    {questionResolution && (
                                                                        <>
                                                                            <h6>Resolução</h6>
                                                                            {parse(questionResolution)}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            )
                                                        })}
                                                        <br />

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            )}
                        </Row>
                    </Container >
                )
                }
            </>
        );
    };
}

export default AnswersSheetExportStructure;