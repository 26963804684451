import React from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import disciplinesService from "presentation/services/disciplines";
import questionTopicsService from "presentation/services/question-topics";
import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import Tree from "rc-tree";
import "rc-tree/assets/index.css"
import { treeView } from "presentation/utils/functions";
import CancelButton from "presentation/components/buttons/cancel";



import { handleChange, handleChangeTree } from "presentation/utils/functions";

class QuestionTopicForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            localLoading: true,
            localError: false,
            message: '',
            disciplines: [],
            topics: []
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        try {
            await disciplinesService.findAll().then(async (result) => {
                if (result.error == true) {
                    this.setState({
                        localLoading: false,
                        localError: true,
                        message: result.message
                    });
                } else {
                    if (result.length == 0) {
                        this.setState({
                            localLoading: false,
                            localError: true,
                            message: "Não há disciplinas cadastradas. Para cadastrar tópicos de questões, por favor, cadastre ao menos uma disciplina...."
                        });
                    } else {
                        await questionTopicsService.findAll().then((topicsResult) => {
                            if (topicsResult.error == true) {
                                this.setState({
                                    localLoading: false,
                                    localError: true,
                                    message: topicsResult.message
                                });
                            } else {
                                this.setState({ disciplines: result, topics: topicsResult, localLoading: false, localError: false });
                            }
                        });
                    }
                }

            });
        } catch (err) {
            this.setState({
                localLoading: false,
                localError: true,
                message: err.message
            });
        }
    }

    render() {
        var { localLoading, localError, message, disciplines, topics } = this.state;
        var { loading, form, handleSubmit, component } = this.props;
        var disciplineId = form.disciplineId || disciplines[0];
        let topicId = form.id || null;


        var filteredTopics = topics.filter(x => x.disciplineId == disciplineId);

        var disciplineTopics = treeView(filteredTopics, topicId);

        var expandedKeys = filteredTopics.map((x) => { return x.id });
        var checkedKeys = form.parentId ? [form.parentId] : [];

        return (
            <>
                {loading === true || localLoading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                {(localLoading === false && localError === true) && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {
                    loading === false && localLoading === false && (

                        <Form>
                            <h4>Informações do tópico</h4>
                            <div className="form mt-2">
                                <Form.Label htmlFor="discipline">Disciplina do tópico</Form.Label>
                                <Form.Select
                                    aria-label="Disciplina"
                                    defaultValue={form.disciplineId}
                                    id="disciplineId"
                                    name="disciplineId"
                                    onChange={(e) => handleChange(e, component)}
                                    aria-describedby="disciplineHelpBlock"
                                    required
                                >
                                    <option>Selecione uma disciplina</option>
                                    {disciplines.map((e, i) => { return <option key={e.id} value={e.id}>{e.name}</option> })}
                                </Form.Select>
                                <Form.Text id="disciplineHelpBlock" muted>
                                    Defina a qual disciplina esse tópico pertence
                                </Form.Text>
                            </div>
                            <div className="form">
                                <Form.Label htmlFor="name">Tópico</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="name"
                                    name="name"
                                    defaultValue={form.name}
                                    onChange={(e) => handleChange(e, component)}
                                    aria-describedby="nameHelpBlock"
                                />
                                <Form.Text id="nameHelpBlock" muted>
                                    Informe título do tópico de questão
                                </Form.Text>
                            </div>
                            <div className="form mt-2">
                                <Form.Label htmlFor="displayName">Nome de exibição do tópico</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="displayName"
                                    name="displayName"
                                    defaultValue={form.displayName}
                                    onChange={(e) => handleChange(e, component)}
                                    aria-describedby="displayNameHelpBlock"
                                />
                                <Form.Text id="displayNameHelpBlock" muted>
                                    Escolha um nome de exibição
                                </Form.Text>
                            </div>
                            <div className="form mt-2">
                                <Form.Label htmlFor="parentId">Tópico pai (opcional)</Form.Label>
                                <Tree
                                    defaultExpandAll={true}
                                    defaultExpandParent={true}
                                    autoExpandParent={true}
                                    defaultSelectedKeys={checkedKeys}
                                    expandedKeys={expandedKeys}
                                    height={200}
                                    itemHeight={20}
                                    style={{ border: '1px solid #eee', padding: '10px' }}
                                    treeData={disciplineTopics}
                                    onSelect={(selectedNodes, e) => handleChangeTree('parentId', selectedNodes[0], component)}
                                />
                                <Form.Text id="parentIdHelpBlock" muted>
                                    Defina a qual é o tópico pai (se houver) deste tópico
                                </Form.Text>
                            </div>
                            <div className="mt-3">
                                <CancelButton
                                    containerProps={{
                                        as: Link,
                                        to: `/topicos-questoes`
                                    }}
                                />
                                <Button disabled={loading || localError} variant="success" className="float-end" onClick={() => handleSubmit()}>Salvar</Button>
                            </div>
                        </Form>
                    )
                }
            </>
        );
    };
}

export default QuestionTopicForm;