import React from "react";
import { Link } from "react-router-dom";
import { Form, Button, Container, Row, Nav, Col, Tab } from "react-bootstrap";

import QuestionsList from 'presentation/pages/activities/elements/questions-list';

import PageAlert from "presentation/components/page-alert";
import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import CancelButton from "presentation/components/buttons/cancel";


import { connect } from "react-redux";
import { getUser } from 'presentation/utils/authentication';


import { handleChange } from "presentation/utils/functions";

import { handleSubmit } from 'presentation/pages/activities/functions/activities';

import classroomsService from "presentation/services/classrooms";
import courseDisciplinesService from "presentation/services/course-disciplines";
import activitiesCategoriesService from "presentation/services/activity-categories";

class ActivityForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            localLoading: true,
            localError: false,
            message: '',
            disciplines: [],
            classrooms: [],
            activityCategories: [],
            userClassrooms: [],
            userDisciplines: [],
            classroomId: null,
        }

    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        let { classroomId, component, sessionParams } = this.props;
        let me = this;
        let localError = false;
        let localLoading = true;
        let classrooms = [];
        let errorMessages = [];
        let message = '';
        let academicPeriodId = sessionParams.currentAcademicPeriod;
        let selectedClassroomId = (component.state.form.classroomId ? component.state.form.classroomId : classroomId);

        const user = getUser();

        let userClassrooms = [];
        let userDisciplines = [];

        if (user.teacherInfo) {
            user.teacherInfo.map((classroom, i) => {
                if (classroom) {
                    userClassrooms.push(classroom.id);
                    classroom.disciplines.map((discipline, j) => {
                        if (discipline) {
                            userDisciplines.push(discipline.id);
                        }
                    });
                }
            });
        }


        try {
            var classroomSearchParams = { academicPeriodId: [academicPeriodId] };
            if (userClassrooms.length > 0) {
                classroomSearchParams.classroomId = userClassrooms;
            }

            await classroomsService.search(classroomSearchParams).then((classroomResults) => {
                if (classroomResults.error == true) {
                    localError = true;
                    errorMessages.push("Não foi possível recuperar os turmas...");
                } else {
                    if (classroomResults.length <= 0) {
                        localError = true;
                        errorMessages.push("Não foi possível recuperar a listagem de turmas disponíveis para você no período acadêmico selecionado...");
                    } else {
                        classrooms = classroomResults;
                        if (!classroomId && !component.state.form.classroomId) {

                            selectedClassroomId = classrooms[0].id;
                        }
                    }
                }
            });


            if (localError === true) {
                localLoading = false;
                message = errorMessages.join(" / ");
            }

            component.setState({ form: { ...component.state.form, classroomId: selectedClassroomId, academicPeriodId: academicPeriodId } }, () => {
                me.setState({ ...this.state, classrooms: classrooms, localError: localError, localLoading: localLoading, message: message, userClassrooms: userClassrooms, userDisciplines: userDisciplines }, async () => { await this.loadDisciplines(false, true); await this.loadActivityCategories() });
            });

        } catch (err) {
            this.setState({
                localLoading: false,
                localError: true,
                message: err.message
            });
        }

    }

    loadDisciplines = async (forceClear = false, hasNextLoadData = false) => {
        let localError = false;
        let localLoading = true;
        let disciplines = [];
        let activityCategories = [];
        let errorMessages = [];
        let message = '';

        let { component, sessionParams } = this.props;

        let academicPeriodId = sessionParams.currentAcademicPeriod;

        let { classrooms, userDisciplines } = this.state;

        let { form } = component.state;

        if (forceClear == true) {
            //zera os campos abaixo da hierarquia da turma
            component.setState({ form: { ...component.state.form, disciplineId: undefined, activityCategoryId: undefined } });
        }


        if (classrooms.length > 0 && form.classroomId > 0) {
            let classroom = classrooms[0];
            if (form.classroomId != null) {
                classroom = classrooms.filter(x => x.id == form.classroomId)[0];
            }

            var searchParams = { courseId: [classroom.courseId], academicPeriodId: [academicPeriodId] };

            if (userDisciplines.length > 0) {
                searchParams.disciplineId = userDisciplines;
            }

            try {

                await courseDisciplinesService.search(searchParams).then((disciplinesResults) => {
                    if (disciplinesResults.error == true) {
                        localError = true;
                        errorMessages.push("Não foi possível recuperar as disciplinas da turma...");
                    } else {
                        if (disciplinesResults.length <= 0) {
                            localError = true;
                            errorMessages.push("Por favor, cadastre a matriz curricular no curso dessa turma, antes de cadastrar uma atividade...");
                        } else {
                            disciplines = disciplinesResults;

                        }
                    }
                });


                if (localError === true) {
                    localLoading = false;
                    message = errorMessages.join(" / ");
                } else {
                    if (hasNextLoadData == false) {
                        localError = false;
                        localLoading = false;
                    }
                }


            } catch (err) {
                this.setState({
                    localLoading: false,
                    localError: true,
                    message: err.message
                });
            }
        } else {
            localLoading = false;
            disciplines = [];
        }
        this.setState({ ...this.state, disciplines: disciplines, activityCategories: activityCategories, localError: localError, localLoading: localLoading, message: message });


    }

    loadActivityCategories = async (forceClear = false, hasNextLoadData = false) => {
        let localError = false;
        let localLoading = true;
        let activityCategories = [];
        let errorMessages = [];
        let message = '';

        let { component } = this.props;

        let { form } = component.state;

        if (forceClear == true) {
            //zera os campos abaixo da hierarquia da disciplina

            component.setState({ form: { ...component.state.form, activityCategoryId: undefined } });
        }

        if (form.disciplineId > 0) {

            var searchParams = { disciplineId: [form.disciplineId] };

            try {

                await activitiesCategoriesService.search(searchParams).then((categoriesResult) => {
                    if (categoriesResult.error == true) {
                        localError = true;
                        errorMessages.push("Não foi possível recuperar as categorias da disciplina...");
                    } else {
                        activityCategories = categoriesResult;
                    }
                });


                if (localError === true) {
                    localLoading = false;
                    message = errorMessages.join(" / ");
                } else {
                    if (hasNextLoadData == false) {
                        localError = false;
                        localLoading = false;
                    }
                }
            } catch (err) {
                this.setState({
                    localLoading: false,
                    localError: true,
                    message: err.message
                });
            }
        } else {
            localLoading = false;
        }
        this.setState({ ...this.state, activityCategories: activityCategories, localError: localError, localLoading: localLoading, message: message });


    }

    handleClassroomChange = async (e, component) => {
        this.setState({ localLoading: true });
        await handleChange(e, component);
        await this.loadDisciplines(true);
    }

    handleDisciplineChange = async (e, component) => {
        this.setState({ localLoading: true });
        await handleChange(e, component);
        await this.loadActivityCategories(true);
    }

    render() {

        let { component, classroomId } = this.props;

        const { form, loading } = component.state;

        const { classrooms, disciplines, activityCategories, localError, localLoading, message } = this.state;

        const { handleClassroomChange, handleDisciplineChange } = this;

        const returnTo = typeof (classroomId) !== 'undefined' ? `/turmas/${classroomId}/atividades` : '/atividades';
        var hasEmptyFieldsOptions = classrooms.length <= 0 || disciplines.length <= 0;

        return (
            <>
                {loading === true || localLoading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                {(localLoading === false && localError === true) && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {
                    loading === false && localLoading === false && (
                        <Form>
                            <Container>
                                <Row>
                                    <Col>
                                        <Tab.Container id="left-tabs" defaultActiveKey="first">
                                            <Row>
                                                <Col sm={2}>
                                                    <Nav variant="pills" className="flex-column">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="first">Geral</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="second">Questões</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </Col>
                                                <Col sm={10}>
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="first">
                                                            <Container>
                                                                <Row>
                                                                    <div className="form mt-2">
                                                                        <Form.Label htmlFor="code">Título</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="title"
                                                                            name="title"
                                                                            defaultValue={form.title}
                                                                            onChange={(e) => handleChange(e, component)}
                                                                            aria-describedby="titleHelpBlock"
                                                                        />
                                                                        <Form.Text id="titleHelpBlock" muted>
                                                                            Informe um título
                                                                        </Form.Text>
                                                                    </div>
                                                                    <div className="form mt-2">
                                                                        <Form.Label htmlFor={`classroomId`}>Turma</Form.Label>
                                                                        <Form.Select
                                                                            aria-label="Turma"
                                                                            defaultValue={form.classroomId}
                                                                            id={`classroomId`}
                                                                            name={`classroomId`}
                                                                            data-field-name="classroomId"
                                                                            onChange={async (e) => { await handleClassroomChange(e, component) }}
                                                                            aria-describedby={`classroomIdHelpBlock`}
                                                                        >
                                                                            <option value="">Defina uma turma</option>
                                                                            {classrooms.map((e, i) => { return <option key={`act-cl-${e.id}`} value={e.id}>{e.name}</option> })}
                                                                        </Form.Select>
                                                                        <Form.Text id={`classroomIdHelpBlock`} muted>
                                                                            Defina qual a turma dessa atividade
                                                                        </Form.Text>
                                                                    </div>
                                                                    <div className="form mt-2">
                                                                        <Form.Label htmlFor={`classroomId`}>Disciplina</Form.Label>
                                                                        <Form.Select
                                                                            aria-label="Disciplina"
                                                                            defaultValue={form.disciplineId}
                                                                            id={`disciplineId`}
                                                                            name={`disciplineId`}
                                                                            data-field-name="disciplineId"
                                                                            onChange={async (e) => await handleDisciplineChange(e, component)}
                                                                            aria-describedby={`disciplineIdHelpBlock`}
                                                                        >
                                                                            <option value="">Defina uma disciplina</option>
                                                                            {disciplines.map((e, i) => { return <option key={`act-dis-${e.discipline.id}`} value={e.discipline.id}>{e.discipline.name}</option> })}
                                                                        </Form.Select>
                                                                        <Form.Text id={`disciplineIdHelpBlock`} muted>
                                                                            Defina a disciplina (opcional)
                                                                        </Form.Text>
                                                                    </div>
                                                                    <div className="form mt-2">
                                                                        <Form.Label htmlFor={`classroomId`}>Categoria</Form.Label>
                                                                        <Form.Select
                                                                            aria-label="Categoria"
                                                                            defaultValue={form.activityCategoryId}
                                                                            id={`activityCategoryId`}
                                                                            name={`activityCategoryId`}
                                                                            data-field-name="activityCategoryId"
                                                                            onChange={(e) => handleChange(e, component)}
                                                                            aria-describedby={`activityCategoryIdHelpBlock`}
                                                                        >
                                                                            <option value="">Defina uma categoria da atividade</option>
                                                                            {activityCategories.map((e, i) => { return <option key={`act-dis-${e.id}`} value={e.id}>{e.name}</option> })}
                                                                        </Form.Select>
                                                                        <Form.Text id={`activityCategoryIdHelpBlock`} muted>
                                                                            Defina a categoria da atividade (opcional)
                                                                        </Form.Text>
                                                                    </div>
                                                                    <div className="form mt-2">
                                                                        <Row>
                                                                            <Col size={6}>
                                                                                <Form.Label htmlFor="code">Data de início</Form.Label>
                                                                                <Form.Control
                                                                                    type="datetime-local"
                                                                                    id="startDate"
                                                                                    name="startDate"
                                                                                    defaultValue={form.startDate}
                                                                                    onChange={(e) => handleChange(e, component)}
                                                                                    aria-describedby="startDateHelpBlock"
                                                                                />
                                                                            </Col>
                                                                            <Col size={6}>
                                                                                <Form.Label htmlFor="code">Data fim</Form.Label>
                                                                                <Form.Control
                                                                                    type="datetime-local"
                                                                                    id="endDate"
                                                                                    name="endDate"
                                                                                    defaultValue={form.endDate}
                                                                                    onChange={(e) => handleChange(e, component)}
                                                                                    aria-describedby="endDateHelpBlock"
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Row>
                                                            </Container>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="second">
                                                            <QuestionsList component={component} />
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                    <Container>
                                                        <Row className="mt-2">
                                                            <div>
                                                                <CancelButton
                                                                    containerProps={{
                                                                        as: Link,
                                                                        to: returnTo
                                                                    }}
                                                                />
                                                                <Button disabled={loading || localLoading || localError || hasEmptyFieldsOptions} variant="success" className="float-end" onClick={() => handleSubmit(component, returnTo)}>Salvar</Button>
                                                            </div>
                                                        </Row>
                                                    </Container>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </Col>
                                </Row>
                            </Container>
                        </Form >
                    )
                }
            </>
        );
    };
}

//Configs Redux para este component
//
//Define quais atributos vou pegar do
//state do Redux
const mapStateToProps = (state) => {
    const { data } = state.sessionParams;
    return { sessionParams: data };
};


//Define quais ações esse component
//vai usar para interagir com o Redux
const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(ActivityForm);
