import axios from "axios";
import { getToken, logout, isAuthenticated } from "presentation/utils/authentication";
import { API_BASE_URL } from "presentation/config/constants";

const baseUrl = API_BASE_URL;

export const init = (baseRouteUrl) => {
  let apiUrl = baseUrl;

  if (!(typeof (baseRouteUrl) === null)) {
    apiUrl = baseUrl + '/' + baseRouteUrl;
  }

  const api = axios.create({
    baseURL: apiUrl
  });

  api.interceptors.request.use(async config => {
    const token = getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  return api;
};

export const get = async (route, action = "", queryParams = {}) => {
  const api = init(route);
  let output = { error: false, message: '' };

  try {
    const response = await api.get(action, { params: queryParams });
    output = response.data;
  } catch (e) {
    checkeUnauthorized(e);
    output.error = true;
    output.message = e.message;
  }

  return output;

};

export const post = async (route, action = "", data = [], headers = { 'content-type': 'application/json' }) => {
  const api = init(route);
  let output = { error: false, message: '' };
  try {
    const response = await api.post(action, data, {
      headers: headers
    });

    output = response.data;
  } catch (e) {
    checkeUnauthorized(e);
    output.error = true;
    output.message = e.message;
  }

  return output;
};

export const postDownload = async (route, action = "", data = [], headers = { 'content-type': 'application/json' }) => {
  const api = init(route);
  let output = { error: false, message: '' };
  try {
    const response = await api.post(action, data, {
      headers: headers,
      responseType: 'blob'
    });

    output = response.data;
  } catch (e) {
    checkeUnauthorized(e);
    output.error = true;
    output.message = e.message;
  }

  return output;
};

export const put = async (route, action = "", data = [], headers = { 'content-type': 'application/json' }) => {
  const api = init(route);
  let output = { error: false, message: '' };

  try {
    const response = await api.put(action, data, {
      headers: headers
    });
    output = response.data;
  } catch (e) {
    checkeUnauthorized(e);
    output.error = true;
    output.message = e.message;
  }

  return output;
};

export const destroy = async (route, action = "", id) => {
  const api = init(route);
  let output = { error: false, message: '' };

  try {
    const response = await api.delete(action + "/" + id);
    output = response.data;
  } catch (e) {
    checkeUnauthorized(e);
    output.error = true;
    output.message = e.message;
  }

  return output;
};

const checkeUnauthorized = e => {
  if (e.response.status == 401) {
    if (isAuthenticated()) {
      logout();
      window.location.reload();
    }
  }
}

const api = {
  init,
  get,
  post,
  postDownload,
  put,
  destroy
}

export default api;