import React from "react";
import { Container, Row, Col, Button, Card, Badge, Table } from "react-bootstrap";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import StudentActivitiesModal from 'presentation/pages/principal/dashboards/students/elements/activities-modal';
import StudentMediaFilesModal from 'presentation/pages/principal/dashboards/students/elements/media-files-modal';

import { Heat } from '@alptugidin/react-circular-progress-bar';

import parse from 'html-react-parser';
import logoBicolor from 'presentation/assets/img/logo-bicolor.png';

class StudentDashboardContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            actionLoading: false,
            error: false,
            message: '',
            data: [],
            scoreData: [],
            studentId: null,
            totalCompleted: 0,
            totalAvailable: 0,
            totalActivities: 0,
            successRate: 0,
            completedRate: 0,
            showStudentActivitiesModal: false,
            showStudentMediaFilesModal: false,
            directories: [],
            classroomName: '',
            disciplineName: '',
            classroomId: null,
            disciplineId: null,
            personDisplayName: undefined
        };
    }

    async componentDidMount() {

        const { contentProps } = this.props;

        this.setState({ loading: true });

        this.setState({ loading: false, ...contentProps });
    }

    openAcitvitiesModal = (classroomName, disciplineName, directories, scores) => {
        this.setState({
            showStudentActivitiesModal: true,
            classroomName: classroomName,
            disciplineName: disciplineName,
            directories: directories,
            scores: scores
        });
    }

    closeActivitiesModal = () => {
        this.setState({
            showStudentActivitiesModal: false,
            classroomName: '',
            disciplineName: '',
            directories: [],
            scores: []
        });
    }

    openMediaFilesModal = (classroomName, disciplineName, classroomId, disciplineId) => {
        this.setState({
            showStudentMediaFilesModal: true,
            classroomName: classroomName,
            disciplineName: disciplineName,
            classroomId: classroomId,
            disciplineId: disciplineId
        });
    }

    closeMediaFilesModal = () => {
        this.setState({
            showStudentMediaFilesModal: false,
            classroomName: '',
            disciplineName: '',
            classroomId: null,
            disciplineId: null
        });
    }

    render() {

        const {
            openAcitvitiesModal,
            closeActivitiesModal,
            openMediaFilesModal,
            closeMediaFilesModal
        } = this;
        const {
            data,
            scoreData,
            showStudentActivitiesModal,
            showStudentMediaFilesModal,
            directories,
            classroomId,
            classroomName,
            studentId,
            disciplineId,
            disciplineName,
            scores,
            totalAvailable,
            totalCompleted,
            totalActivities,
            successRate,
            completedRate,
            person
        } = this.state;

        const pendingLabel = totalAvailable == 1 ? 'atividade<br />pendente' : 'atividades <br />pendentes';
        const completedLabel = totalCompleted == 1 ? 'atividade realizada' : 'atividades realizadas';
        const totalActivitiesLabel = totalActivities == 1 ? 'atividade disponibilizada' : 'atividades disponibilizadas';
        let personDisplayName = person ? (person.displayName ? person.displayName : (person.name ? person.name : "")) : "";

        return (
            <div>
                <Container>
                    <Row>
                        <Col>
                            <Card className="student-dashboard-welcome">
                                <Card.Body>
                                    <Row>
                                        <Col className="welcome-headline">
                                            <img src={logoBicolor} id="logo-bicolor" />
                                            <h4 className="font-promo-bolder">Olá, {personDisplayName ? personDisplayName : "aluno(a)"} <br />Bem vindo de volta!</h4>
                                            <p className="font-promo">Confira suas atividades pendentes, concluídas <br />e seu progresso aqui. Bom estudo!</p>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="dashboard-content">
                    <Row>
                        <Col>
                            <Container>
                                <Row className="mt-4">
                                    <Col xs={12} lg={6} xl={3} className="mt-4">
                                        <Card className="student-dashboard-welcome-indicator no-border text-center card-indicator-error">
                                            <Card.Body><h4>{totalAvailable}</h4><small>{parse(pendingLabel)}</small></Card.Body>
                                        </Card>
                                    </Col>
                                    <Col xs={12} lg={6} xl={3} className="mt-4">

                                        <Card className="student-dashboard-welcome-indicator no-border text-center card-indicator-success">
                                            <Card.Body>
                                                <Row>
                                                    <Col>
                                                        <h4>{totalCompleted}</h4><small>{parse(completedLabel)}</small>
                                                    </Col>
                                                    <Col>
                                                        <h4>{totalActivities}</h4><small>{parse(totalActivitiesLabel)}</small>
                                                    </Col>
                                                </Row></Card.Body>
                                        </Card>
                                    </Col>
                                    <Col xs={12} lg={6} xl={3} className="mt-4">
                                        <Card className="student-dashboard-welcome-indicator">
                                            <Card.Body>
                                                <center>
                                                    <div className="progress-circle-container">
                                                        <Heat
                                                            progress={completedRate}
                                                            range={{ from: 0, to: 100 }}
                                                            sign={{ value: '%', position: 'end' }}
                                                            text={'realizado'}
                                                            showMiniCircle={false}
                                                            showValue={true}
                                                            revertBackground={true}
                                                            sx={{
                                                                strokeColor: '#ff0000',
                                                                barWidth: 2,
                                                                bgStrokeColor: '#fefefe',
                                                                bgColor: '#d1d1d1',
                                                                shape: 'threequarters',
                                                                strokeLinecap: 'round',
                                                                valueSize: 20,
                                                                valueColor: '#495057',
                                                                textSize: 14,
                                                                textColor: '#495057',
                                                                loadingTime: 1000,
                                                                miniCircleColor: '#ff0000',
                                                                miniCircleSize: 1,
                                                                valueAnimation: true,
                                                                intersectionEnabled: true
                                                            }}
                                                        />
                                                    </div>
                                                </center>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col xs={12} lg={6} xl={3} className="mt-4">
                                        <Card className="student-dashboard-welcome-indicator">
                                            <Card.Body>
                                                <center>
                                                    <div className="progress-circle-container">
                                                        <Heat
                                                            progress={successRate}
                                                            range={{ from: 0, to: 100 }}
                                                            sign={{ value: '%', position: 'end' }}
                                                            text={'aproveitamento'}
                                                            showMiniCircle={false}
                                                            showValue={true}
                                                            revertBackground={true}
                                                            sx={{
                                                                strokeColor: '#ff0000',
                                                                barWidth: 2,
                                                                bgStrokeColor: '#fefefe',
                                                                bgColor: '#d1d1d1',
                                                                shape: 'threequarters',
                                                                strokeLinecap: 'round',
                                                                valueSize: 20,
                                                                valueColor: '#495057',
                                                                textSize: 12,
                                                                textColor: '#495057',
                                                                loadingTime: 1000,
                                                                miniCircleColor: '#ff0000',
                                                                miniCircleSize: 1,
                                                                valueAnimation: true,
                                                                intersectionEnabled: true
                                                            }}
                                                        />
                                                    </div>
                                                </center>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className="mb-5">
                                    {data.map((classroom, index) => {
                                        return (
                                            <Col lg={6} key={index}>
                                                <h4 className="mb-4 mt-4" >{classroom.name}</h4>
                                                <Card>
                                                    <Card.Body>
                                                        <Table borderless className="mt-4 dashboard-table">
                                                            <tbody>
                                                                {classroom.disciplines.map((discipline, disciplineIndex) => {
                                                                    var disciplineScores = scoreData.filter(x => x.disciplineId == discipline.id && x.classroomId == classroom.id);
                                                                    return (
                                                                        <tr key={disciplineIndex}>
                                                                            <td width={"45%"} >{discipline.name}</td>
                                                                            <td width={"25%"} className="text-end">
                                                                                <Button size="sm" variant="outline-success" onClick={(e) => openMediaFilesModal(classroom.name, discipline.name, classroom.id, discipline.id, studentId)}>materiais</Button>
                                                                            </td>
                                                                            <td width={"25%"} className="text-end">
                                                                                <Button className="button-with-badge" size="sm" variant="outline-success" onClick={(e) => openAcitvitiesModal(classroom.name, discipline.name, discipline.directories, disciplineScores)}>
                                                                                    atividades
                                                                                </Button>
                                                                            </td>
                                                                            <td width={"5%"} className="text-start">
                                                                                {discipline.availableActivities > 0 && (
                                                                                    <span>
                                                                                        <Badge bg="danger">{discipline.availableActivities}</Badge>
                                                                                        <span className="visually-hidden">atividades disponíveis</span>
                                                                                    </span>
                                                                                )}
                                                                            </td>

                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </Table>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                                </Row>
                                {showStudentActivitiesModal == true && (
                                    <StudentActivitiesModal
                                        show={showStudentActivitiesModal}
                                        onClose={closeActivitiesModal}
                                        directories={directories}
                                        scores={scores}
                                        classroomName={classroomName}
                                        disciplineName={disciplineName}
                                    />
                                )}
                                {showStudentMediaFilesModal == true && (
                                    <StudentMediaFilesModal
                                        show={showStudentMediaFilesModal}
                                        onClose={closeMediaFilesModal}
                                        studentId={studentId}
                                        classroomId={classroomId}
                                        disciplineId={disciplineId}
                                        classroomName={classroomName}
                                        disciplineName={disciplineName}
                                    />
                                )}
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    };
}

export default StudentDashboardContent;