import React from "react";

import AnswersSheetExportStructure from 'presentation/pages/activities/elements/answers-sheet-export-structure';

class AnswersSheetExportStructureContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            localLoading: false,
            localError: false,
            message: ''
        }

    }


    render() {

        let { questions, activity, exportMode } = this.props;

        return (
            <html>
                <head>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <title>Export</title>
                </head>
                <body>
                    <AnswersSheetExportStructure questions={questions} activity={activity} exportMode={exportMode} />
                </body>
            </html>
        );
    };
}

export default AnswersSheetExportStructureContainer;