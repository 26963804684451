import React from "react";
import PageHeader from "presentation/pages/layout/elements/page-header";
import { Container, Row, Col, Table, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import PageModal from "presentation/components/page-modal";

import CancelButton from "presentation/components/buttons/cancel";
import AddButton from "presentation/components/buttons/add";


import AddCoursesDisciplinesModal from "presentation/pages/course-disciplines/add-modal";

import { handleResultData } from "presentation/utils/functions";

import academicPeriodsService from "presentation/services/academic-periods";
import coursesService from "presentation/services/courses";
import courseDisciplinesService from "presentation/services/course-disciplines";

class CourseDisciplineMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      actionLoading: false,
      error: false,
      message: '',
      form: {
        academicPeriodId: null
      },
      data: [],
      academicPeriods: [],
      course: {
        name: ''
      },
      showConfirmModal: false,
      showAddDisciplineModal: false,
      targetDeleteId: null,
    };
    this.loadData = this.loadData.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }

  async componentDidMount() {
    const { id } = this.props.match.params
    await this.loadData(id);
  }

  loadData = async (id) => {
    await coursesService.findById(id).then((result) => {
      let handledResult = handleResultData(result);

      this.setState({ course: handledResult });
    });

    await academicPeriodsService.findAll().then(async (result) => {
      let academicPeriods = handleResultData(result);

      let academicPeriodId = null;

      if (academicPeriods.length > 0) {
        academicPeriodId = academicPeriods[0].id;
      }
      this.setState({ academicPeriods: academicPeriods, form: { academicPeriodId: academicPeriodId } }, () => { this.loadDisciplines() });
    });

  }

  loadDisciplines = async () => {
    const { id } = this.props.match.params
    let { form } = this.state;
    if (form.academicPeriodId) {
      await courseDisciplinesService.search({ courseId: [parseInt(id)], academicPeriodId: [parseInt(form.academicPeriodId)] }).then((result) => {
        let handledResult = handleResultData(result);
        this.setState({ data: handledResult, loading: false });
      });
    }

  }

  handleChange = (e) => {
    let { value } = e.target;
    this.setState({ form: { academicPeriodId: value } }, () => { this.loadDisciplines(); });
  }

  openModal = (id) => {
    this.setState({ targetDeleteId: id, showConfirmModal: true });
  }

  closeModal = () => {
    this.setState({ showConfirmModal: false, targetDeleteId: null });
  }

  openAddDisciplineModal = () => {
    this.setState({ showAddDisciplineModal: true });
  }

  closeAddDisciplineModal = () => {
    this.setState({ showAddDisciplineModal: false });
  }

  deleteItem = () => {
    const { loadDisciplines } = this;
    if (this.state.targetDeleteId) {
      this.setState({ loading: true, actionLoading: true });
      courseDisciplinesService.remove(this.state.targetDeleteId).then((result) => {
        this.setState({ loading: false, actionLoading: false, targetDeleteId: null, showConfirmModal: false }, () => {
          loadDisciplines();
        });
      });
    }
  }

  render() {
    const { id } = this.props.match.params;

    const { loading, actionLoading, error, message, data, showConfirmModal, showAddDisciplineModal, course, form, academicPeriods } = this.state;

    const { deleteItem, closeModal, openModal, openAddDisciplineModal, closeAddDisciplineModal, loadDisciplines, handleChange } = this;

    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && error === false && data.length <= 0 && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />}
        {loading === false && (
          <Container>
            <Row>
              <h4>Matriz curricular do curso {course.name}
                <AddButton
                  containerProps={{
                    onClick: () => openAddDisciplineModal()

                  }}
                />
              </h4>
              <Container>
                <Row className="mt-5">
                  <Col xs={{ span: 2, offset: 10 }}>
                    <Form.Select
                      aria-label="Período Acadêmico"
                      defaultValue={form.academicPeriodId}
                      id="academicPeriodId"
                      name="academicPeriodId"
                      onChange={(e) => { handleChange(e) }}
                      required
                    >
                      {academicPeriods.map((e, i) => { return <option key={e.id} value={e.id}>{e.name}</option> })}
                    </Form.Select>
                  </Col>
                </Row>
                <Table className="mt-4">
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Disciplina</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 && data.map((element, index) => {
                      return (
                        <tr key={element.id}>
                          <td>{element.discipline.code}</td>
                          <td>{element.discipline.name}</td>
                          <td className="text-end">
                            <Button variant="outline-secondary" onClick={() => openModal(element.id)}>Remover</Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Container>
              <div className="mt-3">
                <CancelButton
                  containerProps={{
                    as: Link,
                    to: `/cursos`
                  }}
                />
              </div>
            </Row>
            <AddCoursesDisciplinesModal
              show={showAddDisciplineModal}
              onClose={closeAddDisciplineModal}
              loadData={loadDisciplines}
              entityId={id}
              academicPeriodId={form.academicPeriodId}
              entityName={course.name}
            />
            <PageModal
              show={showConfirmModal}
              onClose={closeModal}
              onConfirm={deleteItem} />
          </Container>
        )}
      </div>
    );
  };
}

export default CourseDisciplineMain;
