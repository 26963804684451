import { postDownload } from "presentation/utils/api";

//Define a rota base desse serviço
const baseRoute = "DataHandler";

export const HTMLtoDOCX = async (postData) => {
    var data = JSON.stringify(postData);
    return await postDownload(baseRoute, "html-to-docx", data);
};



const dataHandlerService = {
    HTMLtoDOCX
}

export default dataHandlerService;