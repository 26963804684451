import React from "react";
import { Container, Row, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { connect } from "react-redux";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import PageModal from "presentation/components/page-modal";

import DropdownMenu from "presentation/components/dropdown-menu/menu";
import EditItemTitle from "presentation/components/dropdown-menu/item-title/edit";
import RemoveItemTitle from "presentation/components/dropdown-menu/item-title/remove";
import { DROPDOWN_MENU_TYPE_ITEM, DROPDOWN_MENU_TYPE_DIVIDER } from 'presentation/model/constants';
import { Mortarboard, People, FileRuled } from 'react-bootstrap-icons';

import AddButton from "presentation/components/buttons/add";

import { handleResultData } from "presentation/utils/functions";

import classroomsService from "presentation/services/classrooms";

class ClassroomMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      actionLoading: false,
      error: false,
      message: '',
      data: [],
      showConfirmModal: false,
      targetDeleteId: null,
    };
    this.loadData = this.loadData.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }

  async componentDidMount() {
    await this.loadData();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { sessionParams } = this.props;
    const { sessionParams: previousSessionParams } = prevProps;

    if (sessionParams.currentAcademicPeriod != previousSessionParams.currentAcademicPeriod) {
      await this.loadData();
    }
  }

  loadData = async () => {
    this.setState({ loading: true }, async () => {
      const { sessionParams } = this.props;
      if (sessionParams.currentAcademicPeriod) {
        await classroomsService.search({ academicPeriodId: [parseInt(sessionParams.currentAcademicPeriod)] }).then((result) => {
          let handledResult = handleResultData(result);

          this.setState({ data: handledResult, error: false, loading: false });
        });
      } else {
        this.setState({ error: true, loading: false, message: "Não foi possível carregar as informações da turma..." });
      }
    });
  }

  openModal = (id) => {
    this.setState({ targetDeleteId: id, showConfirmModal: true });
  }

  closeModal = () => {
    this.setState({ showConfirmModal: false, targetDeleteId: null });
  }

  deleteItem = async () => {
    const { loadData } = this;
    if (this.state.targetDeleteId) {
      this.setState({ loading: true, actionLoading: true });
      classroomsService.remove(this.state.targetDeleteId).then((result) => {
        this.setState({ loading: false, actionLoading: false, targetDeleteId: null, showConfirmModal: false }, async () => {
          await loadData();
        });
      });
    }
  }

  render() {
    let { location } = this.props;
    const { loading, actionLoading, error, message, data, showConfirmModal } = this.state;

    const { deleteItem, closeModal, openModal } = this;


    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && error === false && data.length <= 0 && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />}
        {loading === false && (
          <Container>
            <Row>
              <h4>
                Listagem de turmas
                <AddButton
                  containerProps={{
                    as: Link,
                    to: `/turmas/adicionar`
                  }}
                />
              </h4>
              <Container>
                <Table className="mt-4">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nome</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 && data.map((element, index) => {
                      return (
                        <tr key={element.id}>
                          <td>{element.id}</td>
                          <td>{element.name}</td>
                          <td className="text-end">
                            <DropdownMenu
                              items={[
                                { type: DROPDOWN_MENU_TYPE_ITEM, title: <><Mortarboard /> Alunos</>, props: { as: Link, to: `/turmas/${element.id}/alunos` } },
                                { type: DROPDOWN_MENU_TYPE_ITEM, title: <><People /> Professores</>, props: { as: Link, to: `/turmas/${element.id}/professores` } },
                                { type: DROPDOWN_MENU_TYPE_ITEM, title: <><FileRuled /> Atividades</>, props: { as: Link, to: `/turmas/${element.id}/atividades` } },
                                { type: DROPDOWN_MENU_TYPE_DIVIDER },
                                { type: DROPDOWN_MENU_TYPE_ITEM, title: <EditItemTitle />, props: { as: Link, to: `/turmas/editar/${element.id}` } },
                                { type: DROPDOWN_MENU_TYPE_ITEM, title: <RemoveItemTitle />, props: { onClick: () => openModal(element.id) } }
                              ]}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Container>
            </Row>
            <PageModal
              show={showConfirmModal}
              onClose={closeModal}
              onConfirm={deleteItem} />
          </Container>
        )}
      </div>
    );
  };
}

//Configs Redux para este component
//
//Define quais atributos vou pegar do
//state do Redux
const mapStateToProps = (state) => {
  const { data } = state.sessionParams;
  return { sessionParams: data };
};


//Define quais ações esse component
//vai usar para interagir com o Redux
const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(ClassroomMain);
