import React from "react";
import { Link } from "react-router-dom";
import { Form, Button, Container, Row, Nav, Col, Tab } from "react-bootstrap";

import QuestionOptionsList from 'presentation/pages/questions/elements/question-options-list';
import QuestionInformationsList from 'presentation/pages/questions/elements/question-informations-list';
import QuestionDisciplineInformationsList from 'presentation/pages/questions/elements/question-discipline-informations-list';

import PageAlert from "presentation/components/page-alert";
import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import CancelButton from "presentation/components/buttons/cancel";


import DefaultEditor from "presentation/components/default-editor";

import { handleChange, handleChangeEditor } from "presentation/utils/functions";

import { hasMultipleInvalidCorrectAnswers, hasNoCorrectAnswers } from 'presentation/pages/questions/functions/question-options';
import { hasInvalidInformation } from 'presentation/pages/questions/functions/question-informations';
import { hasInvalidDisciplineInformation } from 'presentation/pages/questions/functions/question-discipline-informations';

import { handleSubmit, handleChangeQuestionType, yearsList } from 'presentation/pages/questions/functions/questions';

import { questionTypes } from 'presentation/model/constants';
import questionDifficultiesService from "presentation/services/question-difficulties";


class QuestionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            localLoading: false,
            localError: false,
            message: '',
            disciplines: [],
            questionOrigins: [],
            questionTopics: [],
            questionDifficulties: [],
        }

    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        let questionDifficulties = [];
        let localError = false;
        let localLoading = true;
        let errorMessages = [];
        let message = '';

        try {

            await questionDifficultiesService.findAll().then((difficultiesResult) => {
                if (difficultiesResult.error == true) {
                    localError = true;
                    errorMessages.push("Não foi possível recuperar os níveis de dificuldade...");
                } else {
                    if (difficultiesResult.length <= 0) {
                        localError = true;
                        errorMessages.push("Por favor, cadastre níveis de dificuldade, antes de cadastrar uma questão...");
                    } else {
                        questionDifficulties = difficultiesResult;
                    }
                }
            });


            if (localError === true) {
                localLoading = false;
                message = errorMessages.join(" / ");
            } else {
                localError = false;
                localLoading = false;
            }

            this.setState({ ...this.state, questionDifficulties: questionDifficulties, localError: localError, localLoading: localLoading, message: message });
        } catch (err) {
            this.setState({
                localLoading: false,
                localError: true,
                message: err.message
            });
        }

    }

    render() {

        let { component } = this.props;

        const { form, loading } = component.state;

        const { questionDifficulties, localError, localLoading, message } = this.state;

        let isDiscursive = form.isDiscursive || false;

        const hasInvalidAnswers = hasMultipleInvalidCorrectAnswers(component);
        const hasInvalidInformations = hasInvalidInformation(component);
        const hasInvalidDisciplineInformations = hasInvalidDisciplineInformation(component);
        const hasNoRightAnswers = hasNoCorrectAnswers(component);

        const years = yearsList();

        return (
            <>
                {loading === true || localLoading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                {(localLoading === false && localError === true) && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {
                    loading === false && localLoading === false && (
                        <Form>
                            <Container>
                                <Row>
                                    <Col>
                                        <Tab.Container id="left-tabs" defaultActiveKey="first">
                                            <Row>
                                                <Col sm={2}>
                                                    <Nav variant="pills" className="flex-column">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="first">Questão</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="second">Origem</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="third">Tópicos</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </Col>
                                                <Col sm={10}>
                                                    {hasInvalidInformations && <div className="mt-5 mb-4"><PageAlert message={"Erro nas informações"} description={"Uma ou mais informações da questão (origem) não foram preenchidas corretamente ..."} type="warning" /></div>}
                                                    {hasInvalidDisciplineInformations && <div className="mt-5 mb-4"><PageAlert message={"Erro nas informações"} description={"Uma ou mais informações da questão (tópicos) não foram preenchidas corretamente ..."} type="warning" /></div>}
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="first">
                                                            <Container>
                                                                <Row>
                                                                    <div className="form mt-2">
                                                                        <Form.Label htmlFor="code">Código</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="code"
                                                                            name="code"
                                                                            defaultValue={form.code}
                                                                            onChange={(e) => handleChange(e, component)}
                                                                            aria-describedby="codeHelpBlock"
                                                                        />
                                                                        <Form.Text id="codeHelpBlock" muted>
                                                                            Informe um código (opicional)
                                                                        </Form.Text>
                                                                    </div>
                                                                    <div className="form mt-2">
                                                                        <Form.Label htmlFor={`questionYear`}>Ano</Form.Label>
                                                                        <Form.Select
                                                                            aria-label="Ano da questão"
                                                                            defaultValue={form.questionYear}
                                                                            id={`questionYear`}
                                                                            name={`questionYear`}
                                                                            data-field-name="questionYear"
                                                                            onChange={(e) => handleChange(e, component)}
                                                                            aria-describedby={`questionYearHelpBlock`}
                                                                        >
                                                                            <option value="">Escolha um ano</option>
                                                                            {years.map((e, i) => { return <option key={e.year} value={e.year}>{e.year}</option> })}
                                                                        </Form.Select>
                                                                        <Form.Text id={`questionYearHelpBlock`} muted>
                                                                            Defina qual é o ano da questão
                                                                        </Form.Text>
                                                                    </div>
                                                                    <div className="form mt-2">
                                                                        <Form.Label htmlFor={`questionDifficultyId`}>Nível de dificuldade</Form.Label>
                                                                        <Form.Select
                                                                            aria-label="Dificuldade da questão"
                                                                            value={form.questionDifficultyId}
                                                                            id={`questionDifficultyId`}
                                                                            name={`questionDifficultyId`}
                                                                            data-field-name="questionDifficultyId"
                                                                            onChange={(e) => handleChange(e, component)}
                                                                            aria-describedby={`questionDifficultyIdHelpBlock`}
                                                                        >
                                                                            <option value="">Defina um nível de dificuldade</option>
                                                                            {questionDifficulties.map((e, i) => { return <option key={e.id} value={e.id}>{e.name}</option> })}
                                                                        </Form.Select>
                                                                        <Form.Text id={`questionDifficultyIdHelpBlock`} muted>
                                                                            Defina qual é o nível de dificuldade da questão
                                                                        </Form.Text>
                                                                    </div>
                                                                    <div className="form mt-2">
                                                                        <Form.Label htmlFor="questionTypeId">Tipo de questão</Form.Label>
                                                                        <Form.Select
                                                                            aria-label="Tipo de questão"
                                                                            defaultValue={form.questionType || 1}
                                                                            id="questionTypeId"
                                                                            name="questionTypeId"
                                                                            onChange={(e) => handleChangeQuestionType(e, component)}
                                                                            aria-describedby="questionTypeIdHelpBlock"
                                                                        >
                                                                            {questionTypes.map((e, i) => { return <option key={e.id} value={e.id}>{e.name}</option> })}
                                                                        </Form.Select>
                                                                        <Form.Text id="questionTypeHelpBlock" muted>
                                                                            Defina qual é o tipo da questão
                                                                        </Form.Text>
                                                                    </div>
                                                                    {!isDiscursive &&
                                                                        <div className="form mt-2">
                                                                            <Form.Label htmlFor="allowMultipleOptionsAnswers">Permite escolha de multiplas anternativas?</Form.Label>
                                                                            <Form.Check
                                                                                type="switch"
                                                                                checked={form.allowMultipleOptionsAnswers || false}
                                                                                label={(form.allowMultipleOptionsAnswers || false) == true ? "Sim" : "Não"}
                                                                                id="allowMultipleOptionsAnswers"
                                                                                name="allowMultipleOptionsAnswers"
                                                                                onChange={(e) => handleChange(e, component)}
                                                                            />
                                                                            <Form.Text id="allowMultipleOptionsAnswersHelpBlock" muted>
                                                                                Define se nessa questão mais de uma alternativa pode ser selecionada. Se esse campo estiver marcado como sim, habilita no formulário de alternativas a possibildiade de dizer que mais de uma alternativa está correta.
                                                                            </Form.Text>
                                                                        </div>
                                                                    }
                                                                    <div className="form mt-2">
                                                                        <Form.Label htmlFor="title">Enunciado</Form.Label>
                                                                        <DefaultEditor
                                                                            id="title"
                                                                            name="title"
                                                                            data={form.title || ""}
                                                                            onChange={(event, editor) => handleChangeEditor('title', event, editor, component)}
                                                                        />
                                                                        <Form.Text id="titleHelpBlock" muted>
                                                                            Informe enunciado da questão
                                                                        </Form.Text>
                                                                    </div>
                                                                </Row>
                                                            </Container>
                                                            {!isDiscursive && hasInvalidAnswers && <div className="mt-5 mb-4"><PageAlert message={"Erro nas alternativas"} description={"Mais de uma alternativa está marcada como correta, mas essa questão só permite uma. Por favor, escolha qual alternativa é a correta..."} type="danger" /></div>}
                                                            {!isDiscursive && hasNoRightAnswers && <div className="mt-5 mb-4"><PageAlert message={"Erro nas alternativas"} description={"Você precisa escolher qual alternativa é a correta..."} type="warning" /></div>}
                                                            <QuestionOptionsList
                                                                component={component}
                                                            />
                                                            <Container>
                                                                <Row>
                                                                    <div className="form mt-2">
                                                                        <Form.Label htmlFor="questionResolution">Resolução</Form.Label>
                                                                        <DefaultEditor
                                                                            id="questionResolution"
                                                                            name="questionResolution"
                                                                            data={form.questionResolution || ""}
                                                                            onChange={(event, editor) => handleChangeEditor('questionResolution', event, editor, component)}
                                                                        />
                                                                        <Form.Text id="questionResolutionHelpBlock" muted>
                                                                            Informe a resolução (explicação) da(s) alternativa(s) corretas da questão
                                                                        </Form.Text>
                                                                    </div>
                                                                </Row>
                                                            </Container>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="second">
                                                            <QuestionInformationsList component={component} />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="third">
                                                            <QuestionDisciplineInformationsList component={component} />
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                    <Container>
                                                        <Row className="mt-2 mb-5">
                                                            <div>
                                                                <CancelButton
                                                                    containerProps={{
                                                                        as: Link,
                                                                        to: `/questoes`
                                                                    }}
                                                                />
                                                                <Button disabled={loading || localLoading || localError || (!isDiscursive && (hasInvalidAnswers || hasNoRightAnswers)) || hasInvalidInformations} variant="success" className="float-end" onClick={() => handleSubmit(component)}>Salvar</Button>
                                                            </div>
                                                        </Row>
                                                    </Container>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </Col>
                                </Row>
                            </Container>
                        </Form >
                    )
                }
            </>
        );
    };
}

export default QuestionForm;