import React from "react";
import { Container, Row, Table, Button, Col } from "react-bootstrap";
import { DraggableTable } from "presentation/components/draggable-table/table";
import { TableStyles } from "presentation/components/draggable-table/table-styles";
import parse from 'html-react-parser';

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import PageModal from "presentation/components/page-modal";

import AddButton from "presentation/components/buttons/add";

import AddQuestionModal from "presentation/pages/activities/elements/add-question-modal";
import QuestionPointsInput from "presentation/pages/activities/elements/question-points-input";

import { handleResultData } from "presentation/utils/functions";

import activityQuestions from "presentation/services/activity-questions";


import {
    arrayMove
} from "@dnd-kit/sortable";

class QuestionsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            actionLoading: false,
            error: false,
            message: '',
            data: [],
            showConfirmModal: false,
            showAddQuestionModal: false,
            targetDeleteId: null,
            draggableTableData: [],
        };
        this.loadData = this.loadData.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.makeData = this.makeData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        let me = this;
        let { makeData } = me;
        let { activityId } = me.props;
        let resultData = [];
        let loading = true;

        if (activityId) {
            let params = { activityId: [activityId] };

            await activityQuestions.search(params).then((result) => {
                let handledResult = handleResultData(result);

                resultData = handledResult;
                loading = false;
            });
        } else {
            loading = false;
        }
        makeData();
        this.setState({ data: resultData, loading: loading });
    }

    openModal = (id) => {
        this.setState({ targetDeleteId: id, showConfirmModal: true });
    }

    closeModal = () => {
        this.setState({ showConfirmModal: false, targetDeleteId: null });
    }

    openAddQuestionModal = () => {
        this.setState({ showAddQuestionModal: true });
    }

    closeAddQuestionModal = () => {
        this.setState({ showAddQuestionModal: false });
    }

    deleteItem = () => {
        let me = this;
        let { component } = me.props;
        let { selectedQuestions, questionInformations } = component.state;
        let { targetDeleteId } = me.state;
        let { makeData } = me;

        if (targetDeleteId) {
            let target = selectedQuestions.filter(x => x.id == targetDeleteId)[0];

            target.isDeleteRequested = true;

            let others = selectedQuestions.filter(x => x.id != targetDeleteId);
            let newValues = [];

            if (target.activityQuestionId) {
                newValues = [...others, target];
            } else {
                newValues = [...others];
            }

            // Points ------
            let newPointValues = questionInformations.filter(x => x.questionId != targetDeleteId);

            component.setState(
                { selectedQuestions: newValues, questionInformations: newPointValues }, () => {
                    makeData();
                    me.setState({ targetDeleteId: null, showConfirmModal: false });
                });
        }
    }

    makeData = () => {
        let { component } = this.props;
        let { selectedQuestions } = component.state;
        var validOptions = selectedQuestions.filter(x => !(x.isDeleteRequested == true)).sort((a, b) => a.order - b.order);
        var data = validOptions.map((e, i) => {
            var id = e.id;
            e.rowOrder = "";
            e.rowTitle = (<div className="table-cell-height-limited">{parse(e.title)}</div>);
            e.rowActions = (<Button variant="outline-secondary" onClick={() => this.openModal(id)}>Remover</Button>);
            e.rowPointsActions = (<QuestionPointsInput key={`qopt-${id}`} component={component} question={e} />);
            return e;
        });
        this.setState({ draggableTableData: data });
    };

    setData = (event) => {
        const { active, over } = event;
        const { component } = this.props;
        const { selectedQuestions } = component.state;

        const { draggableTableData } = this.state;

        const items = draggableTableData?.map(({ id }) => id);

        if (active.id !== over.id) {

            const oldIndex = items.indexOf(active.id);
            const newIndex = items.indexOf(over.id);
            let reorderedData = arrayMove(draggableTableData, oldIndex, newIndex);

            let ordersDisctionary = [];

            reorderedData.map((e, i) => {
                e.order = i;
                ordersDisctionary.push({ questionId: e.id, order: i });
                return e;
            });

            selectedQuestions.map((q, i) => {
                let order = ordersDisctionary.filter(x => x.questionId == q.id)[0]?.order;
                q.order = order;
                return q;
            });

            component.setState({ selectedQuestions: selectedQuestions }, () => { this.setState({ draggableTableData: reorderedData }) });


        }
    };


    render() {
        const { loading, actionLoading, error, message, showConfirmModal, showAddQuestionModal, draggableTableData } = this.state;

        const { deleteItem, closeModal, openAddQuestionModal, closeAddQuestionModal, setData, makeData } = this;

        const { component } = this.props;

        const headers = [
            { Header: "#", accessor: "rowOrder", style: { width: "10%", textAlign: "center" } },
            { Header: "Questão", accessor: "rowTitle", style: { width: "70%" } },
            { Header: "Pontos", accessor: "rowPointsActions", style: { width: "15%" } },
            { Header: "Ações", accessor: "rowActions", style: { width: "5%" } }
        ];
        return (
            <div>
                {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
                {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {loading === false && error === false && draggableTableData.length <= 0 && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />}
                {loading === false && (
                    <Container>
                        <Row>
                            <Col size={4} className="mt-4">
                                <AddButton
                                    containerProps={{
                                        onClick: () => openAddQuestionModal()

                                    }}
                                />
                            </Col>
                            <Container>
                                <h6 className="float-end mt-4 mb-4">Total de questões: {draggableTableData.length || 0}</h6>
                                <TableStyles>
                                    <DraggableTable columns={headers} data={draggableTableData} setData={setData} />
                                </TableStyles>
                            </Container>
                        </Row>
                        <AddQuestionModal
                            show={showAddQuestionModal}
                            afterSubmit={makeData}
                            onClose={closeAddQuestionModal}
                            component={component}
                        />
                        <PageModal
                            show={showConfirmModal}
                            onClose={closeModal}
                            onConfirm={deleteItem} />
                    </Container>
                )}
            </div>
        );
    };
}

export default QuestionsList;
