import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import { FiMenu } from 'react-icons/fi';

import { Form } from 'react-bootstrap';

import { getUser, logout } from 'presentation/utils/authentication';

import academicPeriodsService from "presentation/services/academic-periods";
import { handleResultData } from "presentation/utils/functions";

import { connect } from "react-redux";
import { add as addSessionParams } from "presentation/redux/modules/sessionParams/actions";

import SideMenu from "presentation/pages/layout/elements/side-menu";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            academicPeriods: [],
            showSideBar: false,
            form: {
                academcPeriodId: null
            },
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    selectDefault = async () => {
        const { sessionParams } = this.props;
        const { academicPeriods } = this.state;

        let academicPeriodId = null;

        if (sessionParams.currentAcademicPeriod) {
            academicPeriodId = sessionParams.currentAcademicPeriod;
        } else {
            if (academicPeriods.length > 0) {
                academicPeriodId = academicPeriods[0].id;

                var data = { ...sessionParams };
                data.currentAcademicPeriod = academicPeriodId;
                await this.props.addSessionParams(data);
            }
        }

        this.setState({ form: { academicPeriodId: academicPeriodId }, loading: false });
    }

    loadData = async () => {
        const { enableAcademicPeriodSelect, selectDefault } = this;
        if (enableAcademicPeriodSelect()) {
            try {
                await academicPeriodsService.findAll().then(async (result) => {
                    let academicPeriods = handleResultData(result);
                    if (result.error == true) {
                        this.setState({
                            loading: false,
                            actionLoading: false,
                            error: true,
                            message: result.message
                        });
                    } else {
                        this.setState({ academicPeriods: academicPeriods }, async () => {
                            await selectDefault();
                        });
                    }

                });
            } catch (err) {
                this.setState({
                    loading: false,
                    actionLoading: false,
                    error: true,
                    message: err.message
                });
            }
        } else {
            this.setState({ loading: false });
        }

    }

    handleChange = async (e) => {
        const { sessionParams } = this.props;
        let { value } = e.target;
        var data = { ...sessionParams };

        data.currentAcademicPeriod = value;

        await this.props.addSessionParams(data);

        this.setState({ form: { academicPeriodId: value } });
    }

    toggleSidebar = () => {
        const { showSideBar } = this.state;
        this.setState({ ...this.state, showSideBar: !showSideBar });

    }

    appLogout = async () => {
        const { history } = this.props;
        await logout();
        history.push('/login');
    }

    enableAcademicPeriodSelect = () => {
        const user = getUser();

        return user.isMaster || user.isTeacher;
    }

    render() {
        const { toggleSidebar, appLogout, handleChange, enableAcademicPeriodSelect, getMenuItems } = this;
        const { showSideBar, academicPeriods, form, loading } = this.state;
        const user = getUser();

        const isAcacademicPeriodSelectAvailable = enableAcademicPeriodSelect();

        return (
            <div>
                <Navbar bg="light" expand="xs">
                    <Container>
                        <Button size="lg" variant="link" className="link-primary" onClick={toggleSidebar}>
                            <FiMenu />
                        </Button>{' '}
                        <Navbar.Brand as={NavLink} to="/">
                            Basico
                        </Navbar.Brand>

                        {isAcacademicPeriodSelectAvailable == true ? (
                            <Form className="justify-content-end">
                                {loading == false && (<Form.Select
                                    aria-label="Período Acadêmico"
                                    defaultValue={form.academicPeriodId}
                                    id="academicPeriodId"
                                    name="academicPeriodId"
                                    onChange={(e) => { handleChange(e) }}
                                    required
                                >
                                    {academicPeriods.map((e, i) => { return <option key={e.id} value={e.id}>{e.name}</option> })}
                                </Form.Select>)}
                            </Form>) : (<p> </p>)}

                    </Container>
                </Navbar>
                <SideMenu user={user} show={showSideBar} toggle={toggleSidebar} logoutAction={appLogout} />
            </div >
        );
    };
}

//Configs Redux para este component
//
//Define quais atributos vou pegar do
//state do Redux
const mapStateToProps = (state) => {
    const { data } = state.sessionParams;
    return { sessionParams: data };
};


//Define quais ações esse component
//vai usar para interagir com o Redux
const mapDispatchToProps = (dispatch) => {
    return {
        addSessionParams: (sessionParams) => dispatch(addSessionParams(sessionParams))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);