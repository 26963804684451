import { handleResultData } from "presentation/utils/functions";
import { checkAvailability } from 'presentation/pages/activities/functions/activities';

import activitiesService from "presentation/services/activities";

export const formatData = (rawData) => {
    let data = [];

    rawData.map((e, i) => {
        if (!data[e.classroomId]) {
            data[e.classroomId] = {
                id: e.classroomId,
                name: e.classroomName,
                disciplines: []
            };
        }

        if (!data[e.classroomId].disciplines[e.disciplineId]) {
            data[e.classroomId].disciplines[e.disciplineId] = {
                id: e.disciplineId,
                name: e.disciplineName,
                totalActivities: 0,
                availableActivities: 0,
                directories: []
            };
        }

        var directoryId = e.activityCategoryId == null ? 0 : 1 + e.activityCategoryId;
        var directoryName = e.activityCategoryId == null ? " Principal " : e.activityCategoryName;

        if (!data[e.classroomId].disciplines[e.disciplineId].directories[directoryId]) {
            data[e.classroomId].disciplines[e.disciplineId].directories[directoryId] = {
                id: directoryId,
                name: directoryName,
                activities: []
            };
        }

        var avInfo = checkAvailability(e);

        e.isAvailable = avInfo.isAvailable;
        e.availabilityMessage = avInfo.message ? avInfo.message : null;

        if (e.isAvailable == true) {
            data[e.classroomId].disciplines[e.disciplineId].availableActivities++;
        }
        data[e.classroomId].disciplines[e.disciplineId].totalActivities++;

        data[e.classroomId].disciplines[e.disciplineId].directories[directoryId].activities.push(e);
    });
    return data;
}

export const calculateStatistics = (data, scoreData) => {
    var totalAvailable = 0;
    var totalActivities = 0;
    var totalCompleted = 0;
    var totalQuestions = 0;
    var totalCorrectedAnswered = 0;
    var successRate = 0;
    var completedRate = 0;

    //Calculating total available

    data.map((classroom, index) => {
        if (classroom.disciplines != undefined && classroom.disciplines.length > 0) {
            classroom.disciplines.map((discipline, dindex) => {
                totalAvailable = parseInt(totalAvailable) + parseInt(discipline.availableActivities);
                totalActivities = parseInt(totalActivities) + parseInt(discipline.totalActivities);
            });
        }
    });

    //Calculating total completed and success rate
    if (scoreData.length > 0) {
        totalCompleted = scoreData.length;

        totalQuestions = scoreData.reduce((acc, o) => acc + parseInt(o.totalQuestions), 0);
        totalCorrectedAnswered = scoreData.reduce((acc, o) => acc + parseInt(o.totalCorrectAnswers), 0);

        if (totalQuestions > 0) {
            successRate = ((totalCorrectedAnswered / totalQuestions) * 100);
        }

    }

    if (totalActivities > 0) {
        completedRate = ((totalCompleted / totalActivities) * 100);
    }

    return { totalAvailable: totalAvailable, totalCompleted: totalCompleted, successRate: successRate, completedRate: completedRate, totalActivities: totalActivities };
}

export const loadStudentData = async (studentId) => {
    let data = [];
    let scoreData = [];

    var activities = await activitiesService.studentActivities(studentId).then((result) => {
        if (result) {
            let handledResult = handleResultData(result);
            data = formatData(handledResult);

        }
    });

    var scores = await activitiesService.studentActivitiesScores(studentId).then((scoresResults) => {
        if (scoresResults) {
            scoreData = handleResultData(scoresResults);
        }

    });

    await Promise.all([activities, scores]);
    let statistics = calculateStatistics(data, scoreData);

    return { scoreData: scoreData, data: data, studentId: studentId, ...statistics };
}

const studentFunctions = {
    formatData,
    calculateStatistics,
    loadStudentData
}

export default studentFunctions;