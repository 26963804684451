import React from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { genders, documentFieldTypes } from 'presentation/model/constants';
import CancelButton from "presentation/components/buttons/cancel";


class ResponsibleForm extends React.Component {
    constructor(props) {
        super(props);

    }
    render() {
        let { loading, form, handleChange, handleSubmit, component, dependentId, studentId } = this.props;
        return (
            <Form className="mb-5">
                <h4>Informações do Aluno</h4>
                <Row className="mb-4">
                    <Col>
                        <div className="form mt-2">
                            <Form.Label htmlFor="registration">Matrícula</Form.Label>
                            <Form.Control
                                type="text"
                                id="studentRegistration"
                                name="studentRegistration"
                                defaultValue={form.studentRegistration}
                                disabled
                                aria-describedby="registrationHelpBlock"
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="form mt-2">
                            <Form.Label htmlFor="registration">Nome</Form.Label>
                            <Form.Control
                                type="text"
                                id="studentName"
                                name="studentName"
                                defaultValue={form.studentName}
                                disabled
                                aria-describedby="registrationHelpBlock"
                            />
                        </div>
                    </Col>
                </Row>
                <h4>Informações do Responsável</h4>
                <div className="form mt-2">
                    <Form.Label htmlFor="name">Nome</Form.Label>
                    <Form.Control
                        type="text"
                        id="name"
                        name="name"
                        defaultValue={form.name}
                        onChange={(e) => handleChange(e, component)}
                        aria-describedby="nameHelpBlock"
                    />
                    <Form.Text id="nameHelpBlock" muted>
                        Informe o nome do responsável
                    </Form.Text>
                </div>
                <div className="form mt-2">
                    <Form.Label htmlFor="displayName">Nome de exibição</Form.Label>
                    <Form.Control
                        type="text"
                        id="displayName"
                        name="displayName"
                        defaultValue={form.displayName}
                        onChange={(e) => handleChange(e, component)}
                        aria-describedby="displayNameHelpBlock"
                    />
                    <Form.Text id="displayNameHelpBlock" muted>
                        Escolha um nome de exibição
                    </Form.Text>
                </div>
                <div className="form mt-2">
                    <Form.Label htmlFor="birthdate">Data de nascimento</Form.Label>
                    <Form.Control
                        type="date"
                        id="birthdate"
                        name="birthdate"
                        defaultValue={form.birthdate}
                        onChange={(e) => handleChange(e, component)}
                        aria-describedby="birthdateHelpBlock"
                    />
                    <Form.Text id="birthdateHelpBlock" muted>
                        Informe a data de nascimento (opicional)
                    </Form.Text>
                </div>
                <div className="form mt-2">
                    <Form.Label htmlFor="gender">Sexo</Form.Label>
                    <Form.Select
                        aria-label="Sexo"
                        defaultValue={form.gender}
                        id="gender"
                        name="gender"
                        onChange={(e) => handleChange(e, component)}
                        aria-describedby="genderHelpBlock"
                        required
                    >
                        <option key="-1">Selecione</option>
                        {genders.map((e, i) => { return <option key={e.id} value={e.id}>{e.name}</option> })}
                    </Form.Select>
                    <Form.Text id="genderHelpBlock" muted>
                        Defina a qual é o sexo biológico
                    </Form.Text>
                </div>
                <div className="form mt-2">
                    <Form.Label htmlFor="documentField">Documento</Form.Label>
                    <Form.Control
                        type="text"
                        id="documentField"
                        name="documentField"
                        defaultValue={form.documentField}
                        onChange={(e) => handleChange(e, component)}
                        aria-describedby="documentFieldNameHelpBlock"
                    />
                    <Form.Text id="documentFieldNameHelpBlock" muted>
                        Informe um documento válido
                    </Form.Text>
                </div>
                <div className="form mt-2">
                    <Form.Label htmlFor="gender">Tipo do documento</Form.Label>
                    <Form.Select
                        aria-label="Tipo do documento"
                        defaultValue={form.documentFieldType}
                        id="documentFieldType"
                        name="documentFieldType"
                        onChange={(e) => handleChange(e, component)}
                        aria-describedby="documentFieldTypeHelpBlock"
                        required
                    >
                        <option key="-1">Selecione</option>
                        {documentFieldTypes.map((e, i) => { return <option key={e.id} value={e.id}>{e.name}</option> })}
                    </Form.Select>
                    <Form.Text id="documentFieldTypeHelpBlock" muted>
                        Defina a qual é o tipo do documento informado
                    </Form.Text>
                </div>
                <div className="mt-3">
                    <CancelButton
                        containerProps={{
                            as: Link,
                            to: `/responsaveis/${dependentId}/${studentId}`
                        }}
                    />
                    <Button disabled={loading} variant="success" className="float-end" onClick={() => handleSubmit()}>Salvar</Button>
                </div>
            </Form>
        );
    };
}

export default ResponsibleForm;