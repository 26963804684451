import React from 'react';
import { Row, Col, Image } from "react-bootstrap";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import userPlaceHolder from 'presentation/assets/img/user.png';

import { Stack, Substack, Grid, Tag, CalendarDate, FileEarmarkBarGraph, FileRuled, FileText, House, Mortarboard, PencilSquare, People, Sliders, Square, Tags, ViewStacked } from 'react-bootstrap-icons';

export default class SideMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    getMenuItems = () => {
        const MENU_ITEM_ALLOW_ALL = "*";
        const { user } = this.props;

        const menuItems = [
            { id: 0, icon: <House />, title: 'Principal', destination: "/", roles: MENU_ITEM_ALLOW_ALL },
            { id: 1, icon: <Mortarboard />, title: 'Alunos', destination: "/alunos", roles: ['master'] },
            { id: 2, icon: <People />, title: ' Colaboradores', destination: "/colaboradores", roles: ['master'] },
            { id: 3, icon: <Stack />, title: ' Cursos', destination: "/cursos", roles: ['master'] },
            { id: 4, icon: <Square />, title: ' Níveis/Segmentos', destination: "/niveis-segmentos", roles: ['master'] },
            { id: 5, icon: <Substack />, title: ' Disciplinas', destination: "/disciplinas", roles: ['master'] },
            { id: 6, icon: <Grid />, title: ' Turmas', destination: "/turmas", roles: ['master'] },
            { id: 7, icon: <Tag />, title: ' Categorias de atividades', destination: "/categorias-atividades", roles: ['master', 'teacher'] },
            { id: 8, icon: <FileRuled />, title: ' Atividades', destination: "/atividades", roles: ['master', 'teacher'] },
            { id: 9, icon: <FileText />, title: ' Materiais', destination: "/materiais", roles: ['master', 'teacher'] },
            { id: 10, icon: <Sliders />, title: ' Dificuldade de questões', destination: "/dificuldades-questoes", roles: ['master'] },
            { id: 11, icon: <Tags />, title: ' Tópicos de questões', destination: "/topicos-questoes", roles: ['master'] },
            { id: 12, icon: <Tag />, title: ' Origem de questões', destination: "/origens-questoes", roles: ['master'] },
            { id: 13, icon: <PencilSquare />, title: ' Questões', destination: "/questoes", roles: ['master'] },
            { id: 14, icon: <CalendarDate />, title: ' Períodos acadêmicos', destination: "/periodos-academicos", roles: ['master'] },
            { id: 15, icon: <FileEarmarkBarGraph />, title: ' Relatórios', destination: "/relatorios", roles: ['master'] },
        ];

        var userMenu = [];

        if (user.roles.length > 0) {

            user.roles.forEach((e, i) => {
                var roleMenu = menuItems.filter(x => x.roles == MENU_ITEM_ALLOW_ALL || x.roles.includes(e.machineName));
                roleMenu.forEach((j, i) => {
                    var currentWithoutItem = userMenu.filter(y => y.id !== j.id);
                    userMenu = [...currentWithoutItem, j]
                });
            });
        }

        return (
            <>
                {userMenu.sort((a, b) => a.id - b.id).map((e, i) => {
                    return (<Nav.Link className="sidebar-link font-promo" key={e.id} as={NavLink} to={e.destination}><div className="menu-item">{e.icon && <span className="menu-item-icon">{e.icon} </span>}<span className="menu-item-title">{e.title}</span></div> </Nav.Link>)
                })}
            </>
        );
    }

    render() {
        let { getMenuItems } = this;
        let { user, show, toggle, logoutAction } = this.props;
        return (
            <Offcanvas show={show} onHide={toggle}>
                <Offcanvas.Header closeButton closeVariant='white' id="side-menu-header">
                    <Row>
                        <Col xs={3}>
                            <Image src={userPlaceHolder} roundedCircle className='side-menu-header-avatar' />
                        </Col>
                        <Col xs={7}>
                            <p className="side-menu-header-user-name font-promo">{user.person ? user.person.displayName : user.email}<br />
                                <small>
                                    <Button className="side-menu-header-logout font-promo-light" size="sm" variant='link' onClick={logoutAction}>Sair</Button>
                                </small>
                            </p>
                        </Col>
                    </Row>
                </Offcanvas.Header>
                <Offcanvas.Body id="side-menu-body">
                    <Nav defaultActiveKey="/" className="flex-column">
                        {getMenuItems()}
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
        );
    };
}