import React from "react";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import { loadStudentData } from 'presentation/pages/principal/dashboards/students/functions/students';

import { getUser } from 'presentation/utils/authentication';

import StudentDashboardContent from "presentation/pages/principal/dashboards/students/elements/student-dashboard-content";

class StudentDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            actionLoading: false,
            error: false,
            message: '',
            person: { name: undefined, displayName: undefined },
            studentData: { data: [] }
        };
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {

        const user = getUser();
        this.setState({ loading: true });

        var studentData = await loadStudentData(user.studentId);
        studentData.person = user.person;
        this.setState({ loading: false, studentData: studentData });

    }

    render() {

        const {
            loading,
            actionLoading,
            error,
            message,
            studentData
        } = this.state;
        return (
            <div>
                {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
                {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {loading === false && error === false && studentData.data.length <= 0 && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />}
                {loading === false &&
                    <StudentDashboardContent contentProps={studentData} />
                }
            </div>
        );
    };
}

export default StudentDashboard;