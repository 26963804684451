import { post, postDownload } from "presentation/utils/api";

//Define a rota base desse serviço
const baseRoute = "Reports";

export const generatePDF = async (postData) => {
    var data = JSON.stringify(postData);
    return await postDownload(baseRoute, "generate/pdf", data);
};

const reportsService = {
    generatePDF,
}

export default reportsService;