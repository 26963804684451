// As rotas estão sendo definidas aqui pois o component
// Components/Breadcrumb.js utiliza esse arquivo para descobrir qual é o título da página

//Ícones disponíveis
import { House } from 'react-bootstrap-icons';

//- Páginas administrativas
import LoginPage from 'presentation/pages/users/login';
import MainPage from 'presentation/pages/principal/main';
import ConfigurationManage from 'presentation/pages/configuration/manage';

//-- Alunos
import StudentMain from 'presentation/pages/students/main';
import StudentAdd from 'presentation/pages/students/add';
import StudentEdit from 'presentation/pages/students/edit';
import StudentUserInfo from 'presentation/pages/students/student-user-info';

//-- Responsaveis
import ResponsibleMain from 'presentation/pages/responsibles/main';
import ResponsibleAdd from 'presentation/pages/responsibles/add';
import ResponsibleEdit from 'presentation/pages/responsibles/edit';
import ResponsibleUserInfo from 'presentation/pages/responsibles/responsible-user-info';

//-- Colaboradores
import EmployeeMain from 'presentation/pages/employees/main';
import EmployeeAdd from 'presentation/pages/employees/add';
import EmployeeEdit from 'presentation/pages/employees/edit';
import EmployeeUserInfo from 'presentation/pages/employees/employee-user-info';

//-- Questões
import QuestionMain from 'presentation/pages/questions/main';
import QuestionAdd from 'presentation/pages/questions/add';
import QuestionEdit from 'presentation/pages/questions/edit';

//-- Origem de Questões
import QuestionOriginMain from 'presentation/pages/question-origins/main';
import QuestionOriginAdd from 'presentation/pages/question-origins/add';
import QuestionOriginEdit from 'presentation/pages/question-origins/edit';

//-- Variações de Origem de Questões
import QuestionOriginVariationMain from 'presentation/pages/question-origin-variations/main';
import QuestionOriginVariationAdd from 'presentation/pages/question-origin-variations/add';
import QuestionOriginVariationEdit from 'presentation/pages/question-origin-variations/edit';

//-- Dificuldades de Questões
import QuestionDifficultyMain from 'presentation/pages/question-difficulties/main';
import QuestionDifficultyAdd from 'presentation/pages/question-difficulties/add';
import QuestionDifficultyEdit from 'presentation/pages/question-difficulties/edit';

//-- Tópico de Questões
import QuestionTopicMain from 'presentation/pages/question-topics/main';
import QuestionTopicAdd from 'presentation/pages/question-topics/add';
import QuestionTopicEdit from 'presentation/pages/question-topics/edit';

//-- Períodos acadêmicos
import AcademicPeriodMain from 'presentation/pages/academic-periods/main';
import AcademicPeriodAdd from 'presentation/pages/academic-periods/add';
import AcademicPeriodEdit from 'presentation/pages/academic-periods/edit';

//-- Níveis/segmentos
import CourseLevelMain from 'presentation/pages/course-levels/main';
import CourseLevelAdd from 'presentation/pages/course-levels/add';
import CourseLevelEdit from 'presentation/pages/course-levels/edit';

//-- Cursos
import CourseMain from 'presentation/pages/courses/main';
import CourseAdd from 'presentation/pages/courses/add';
import CourseEdit from 'presentation/pages/courses/edit';
import CourseDisciplineMain from 'presentation/pages/course-disciplines/main';

//-- Turmas
import ClassroomMain from 'presentation/pages/classrooms/main';
import ClassroomAdd from 'presentation/pages/classrooms/add';
import ClassroomEdit from 'presentation/pages/classrooms/edit';
import ClassroomStudentsMain from 'presentation/pages/classrooms/classroom-students';
import ClassroomTeachersMain from 'presentation/pages/classrooms/classroom-teachers';
import ClassroomActivitiesMain from 'presentation/pages/classrooms/classroom-activities';

//-- Disciplinas
import DisciplineMain from 'presentation/pages/disciplines/main';
import DisciplineAdd from 'presentation/pages/disciplines/add';
import DisciplineEdit from 'presentation/pages/disciplines/edit';

//-- Categorias de atividades
import ActivityCategoryMain from 'presentation/pages/activity-categories/main';
import ActivityCategoryAdd from 'presentation/pages/activity-categories/add';
import ActivityCategoryEdit from 'presentation/pages/activity-categories/edit';

//-- Atividades
import ActivityMain from 'presentation/pages/activities/main';
import ActivityAdd from 'presentation/pages/activities/add';
import ActivityEdit from 'presentation/pages/activities/edit';
import ActivityStudents from 'presentation/pages/activities/activity-students';
import ActivityFill from 'presentation/pages/activities/fill';
import ActivityManualFill from 'presentation/pages/activities/manual-fill';
import ActivityView from 'presentation/pages/activities/view';
import ActivityAnswersSheet from 'presentation/pages/activities/answers-sheet';
import ActivityCorrections from 'presentation/pages/activities/corrections';


//-- Materiais
import MediaFileMain from 'presentation/pages/media-files/main';
import MediaFileAdd from 'presentation/pages/media-files/add';
import MediaFileEdit from 'presentation/pages/media-files/edit';

// - Páginas gerais
import NotFound from 'presentation/pages/errors/not-found';

// - Relatórios
import ReportMain from 'presentation/pages/reports/main';

var routes = [];
export const addRoutes = (newItems) => {
    routes = [...routes, ...newItems];
};

//- Páginas administrativas

addRoutes(
    [
        {
            'title': 'Login',
            'path': '/login',
            'exact': true,
            'isPrivate': false,
            'component': LoginPage,
            'template': 'external',
        },
        {
            'title': 'Principal',
            'path': '/',
            'exact': true,
            'isPrivate': true,
            'component': MainPage,
            'icon': <House />,
        },
        {
            'title': 'Configurações do sistema',
            'path': '/configuracoes',
            'exact': true,
            'isPrivate': true,
            'component': ConfigurationManage,
        }
    ]
);

//-- Alunos

addRoutes(
    [
        {
            'title': 'Alunos',
            'path': '/alunos',
            'exact': true,
            'isPrivate': true,
            'component': StudentMain,
        },
        {
            'title': 'Novo aluno',
            'path': '/alunos/adicionar',
            'exact': true,
            'isPrivate': true,
            'component': StudentAdd,
        },
        {
            'title': 'Editar aluno',
            'path': '/alunos/editar/:id',
            'exact': true,
            'isPrivate': true,
            'component': StudentEdit,
        },
        {
            'title': 'Dados de acesso do aluno',
            'path': '/alunos/acesso/:id',
            'exact': true,
            'isPrivate': true,
            'component': StudentUserInfo,
        },
    ]
);

//-- Responsáveis

addRoutes(
    [
        {
            'title': 'Responsáveis',
            'path': '/responsaveis/:dependentId/:studentId',
            'exact': true,
            'isPrivate': true,
            'component': ResponsibleMain,
        },
        {
            'title': 'Novo responsável',
            'path': '/responsaveis/adicionar/:dependentId/:studentId',
            'exact': true,
            'isPrivate': true,
            'component': ResponsibleAdd,
        },
        {
            'title': 'Editar responsável',
            'path': '/responsaveis/editar/:id/:dependentId/:studentId',
            'exact': true,
            'isPrivate': true,
            'component': ResponsibleEdit,
        },
        {
            'title': 'Dados de acesso do responsável',
            'path': '/responsaveis/acesso/:id/:dependentId/:studentId',
            'exact': true,
            'isPrivate': true,
            'component': ResponsibleUserInfo,
        },
    ]
);

//-- Colaboradores

addRoutes(
    [
        {
            'title': 'Colaboradores',
            'path': '/colaboradores',
            'exact': true,
            'isPrivate': true,
            'component': EmployeeMain,
        },
        {
            'title': 'Novo colaborador',
            'path': '/colaboradores/adicionar',
            'exact': true,
            'isPrivate': true,
            'component': EmployeeAdd,
        },
        {
            'title': 'Editar colaborador',
            'path': '/colaboradores/editar/:id',
            'exact': true,
            'isPrivate': true,
            'component': EmployeeEdit,
        },
        {
            'title': 'Dados de acesso do colaborador',
            'path': '/colaboradores/acesso/:id',
            'exact': true,
            'isPrivate': true,
            'component': EmployeeUserInfo,
        },
    ]
);

//-- Questões

addRoutes(
    [
        {
            'title': 'Questões',
            'path': '/questoes',
            'exact': true,
            'isPrivate': true,
            'component': QuestionMain,
        },
        {
            'title': 'Nova questão',
            'path': '/questoes/adicionar',
            'exact': true,
            'isPrivate': true,
            'component': QuestionAdd,
        },
        {
            'title': 'Editar questão',
            'path': '/questoes/editar/:id',
            'exact': true,
            'isPrivate': true,
            'component': QuestionEdit,
        },
    ]
);

//-- Origem de Questões

addRoutes(
    [
        {
            'title': 'Origens de Questões',
            'path': '/origens-questoes',
            'exact': true,
            'isPrivate': true,
            'component': QuestionOriginMain,
        },
        {
            'title': 'Nova origem de questão',
            'path': '/origens-questoes/adicionar',
            'exact': true,
            'isPrivate': true,
            'component': QuestionOriginAdd,
        },
        {
            'title': 'Editar origem de questão',
            'path': '/origens-questoes/editar/:id',
            'exact': true,
            'isPrivate': true,
            'component': QuestionOriginEdit,
        },
    ]
);

//-- Variações de Origem de Questões

addRoutes(
    [
        {
            'title': 'Variações de origens de Questões',
            'path': '/variacoes-origens-questoes/origem/:id',
            'exact': true,
            'isPrivate': true,
            'component': QuestionOriginVariationMain,
        },
        {
            'title': 'Nova variação de origem de questão',
            'path': '/variacoes-origens-questoes/adicionar/:id',
            'exact': true,
            'isPrivate': true,
            'component': QuestionOriginVariationAdd,
        },
        {
            'title': 'Editar variação de origem de questão',
            'path': '/variacoes-origens-questoes/editar/:id',
            'exact': true,
            'isPrivate': true,
            'component': QuestionOriginVariationEdit,
        },
    ]
);

//-- Dificuldades de Questões

addRoutes(
    [
        {
            'title': 'Dificuldades de Questões',
            'path': '/dificuldades-questoes',
            'exact': true,
            'isPrivate': true,
            'component': QuestionDifficultyMain,
        },
        {
            'title': 'Nova dificuldade de questão',
            'path': '/dificuldades-questoes/adicionar',
            'exact': true,
            'isPrivate': true,
            'component': QuestionDifficultyAdd,
        },
        {
            'title': 'Editar dificuldade de questão',
            'path': '/dificuldades-questoes/editar/:id',
            'exact': true,
            'isPrivate': true,
            'component': QuestionDifficultyEdit,
        },
    ]
);

//-- Tópico de Questões

addRoutes(
    [
        {
            'title': 'Tópicos de Questões',
            'path': '/topicos-questoes',
            'exact': true,
            'isPrivate': true,
            'component': QuestionTopicMain,
        },
        {
            'title': 'Nova tópico de questão',
            'path': '/topicos-questoes/adicionar',
            'exact': true,
            'isPrivate': true,
            'component': QuestionTopicAdd,
        },
        {
            'title': 'Editar tópico de questão',
            'path': '/topicos-questoes/editar/:id',
            'exact': true,
            'isPrivate': true,
            'component': QuestionTopicEdit,
        },
    ]
);

//-- Períodos acadêmicos
addRoutes(
    [
        {
            'title': 'Períodos acadêmicos',
            'path': '/periodos-academicos',
            'exact': true,
            'isPrivate': true,
            'component': AcademicPeriodMain,
        },
        {
            'title': 'Novo período acadêmico',
            'path': '/periodos-academicos/adicionar',
            'exact': true,
            'isPrivate': true,
            'component': AcademicPeriodAdd,
        },
        {
            'title': 'Editar período acadêmico',
            'path': '/periodos-academicos/editar/:id',
            'exact': true,
            'isPrivate': true,
            'component': AcademicPeriodEdit,
        },
    ]
);

//-- Níveis/segmentos

addRoutes(
    [
        {
            'title': 'Níveis/Segmentos',
            'path': '/niveis-segmentos',
            'exact': true,
            'isPrivate': true,
            'component': CourseLevelMain,
        },
        {
            'title': 'Novo nível/segmento',
            'path': '/niveis-segmentos/adicionar',
            'exact': true,
            'isPrivate': true,
            'component': CourseLevelAdd,
        },
        {
            'title': 'Editar nível/segmento',
            'path': '/niveis-segmentos/editar/:id',
            'exact': true,
            'isPrivate': true,
            'component': CourseLevelEdit,
        },
    ]
);

//-- Cursos
addRoutes(
    [
        {
            'title': 'Cursos',
            'path': '/cursos',
            'exact': true,
            'isPrivate': true,
            'component': CourseMain,
        },
        {
            'title': 'Adicionar curso',
            'path': '/cursos/adicionar',
            'exact': true,
            'isPrivate': true,
            'component': CourseAdd,
        },
        {
            'title': 'Editar curso',
            'path': '/cursos/editar/:id',
            'exact': true,
            'isPrivate': true,
            'component': CourseEdit,
        },
        {
            'title': 'Matriz curricular do curso',
            'path': '/cursos/:id/matriz-curricular',
            'exact': true,
            'isPrivate': true,
            'component': CourseDisciplineMain,
        },
    ]
);

//-- Turmas
addRoutes(
    [
        {
            'title': 'Turmas',
            'path': '/turmas',
            'exact': true,
            'isPrivate': true,
            'component': ClassroomMain,
        },
        {
            'title': 'Adicionar turma',
            'path': '/turmas/adicionar',
            'exact': true,
            'isPrivate': true,
            'component': ClassroomAdd,
        },
        {
            'title': 'Editar turma',
            'path': '/turmas/editar/:id',
            'exact': true,
            'isPrivate': true,
            'component': ClassroomEdit,
        },
        {
            'title': 'Alunos da turma',
            'path': '/turmas/:id/alunos',
            'exact': true,
            'isPrivate': true,
            'component': ClassroomStudentsMain,
        },
        {
            'title': 'Professores da turma',
            'path': '/turmas/:id/professores',
            'exact': true,
            'isPrivate': true,
            'component': ClassroomTeachersMain,
        },
        {
            'title': 'Atividades da turma',
            'path': '/turmas/:id/atividades',
            'exact': true,
            'isPrivate': true,
            'component': ClassroomActivitiesMain,
        }
    ]
);


//-- Disciplinas


addRoutes(
    [
        {
            'title': 'Disciplinas',
            'path': '/disciplinas',
            'exact': true,
            'isPrivate': true,
            'component': DisciplineMain,
        },
        {
            'title': 'Nova disciplina',
            'path': '/disciplinas/adicionar',
            'exact': true,
            'isPrivate': true,
            'component': DisciplineAdd,
        },
        {
            'title': 'Editar disciplina',
            'path': '/disciplinas/editar/:id',
            'exact': true,
            'isPrivate': true,
            'component': DisciplineEdit,
        },
    ]
);

//-- Categorias de atividades

addRoutes(
    [
        {
            'title': 'Categorias de atividades',
            'path': '/categorias-atividades',
            'exact': true,
            'isPrivate': true,
            'component': ActivityCategoryMain,
        },
        {
            'title': 'Nova categoria',
            'path': '/categorias-atividades/adicionar',
            'exact': true,
            'isPrivate': true,
            'component': ActivityCategoryAdd,
        },
        {
            'title': 'Editar categoria',
            'path': '/categorias-atividades/editar/:id',
            'exact': true,
            'isPrivate': true,
            'component': ActivityCategoryEdit,
        },
    ]
);

//-- Atividades

addRoutes(
    [
        {
            'title': 'Atividades',
            'path': '/atividades',
            'exact': true,
            'isPrivate': true,
            'component': ActivityMain,
        },
        {
            'title': 'Nova atividade',
            'path': '/atividades/adicionar/:id?',
            'exact': true,
            'isPrivate': true,
            'component': ActivityAdd,
        },
        {
            'title': 'Editar atividade',
            'path': '/atividades/editar/:id/:classroomId?',
            'exact': true,
            'isPrivate': true,
            'component': ActivityEdit,
        },
        {
            'title': 'Visualizar entrega',
            'path': '/atividades/entregas/ver/:id/:studentId',
            'hideBreadcrumb': true,
            'exact': true,
            'isPrivate': true,
            'component': ActivityView,
        },
        {
            'title': 'Avaliação',
            'path': '/atividades/avaliacao/:id',
            'exact': true,
            'isPrivate': true,
            'component': ActivityCorrections,
        },
        {
            'title': 'Gabarito',
            'path': '/atividades/gabarito/:id',
            'exact': true,
            'isPrivate': true,
            'component': ActivityAnswersSheet,
        },
        {
            'title': 'Entregas da atividade',
            'path': '/atividades/entregas/:id/:classroomId/:disciplineId',
            'exact': true,
            'isPrivate': true,
            'component': ActivityStudents,
        },
        {
            'title': 'Realizar atividade',
            'path': '/atividades/responder',
            'hideBreadcrumb': true,
            'exact': true,
            'isPrivate': true,
            'component': ActivityFill,
        },
        {
            'title': 'Preenchimento manual',
            'path': '/atividades/preenchimento-manual/:id/:classroomId/:disciplineId',
            'exact': true,
            'isPrivate': true,
            'component': ActivityManualFill,
        },
    ]
);

//-- Materiais


addRoutes(
    [
        {
            'title': 'Materiais',
            'path': '/materiais',
            'exact': true,
            'isPrivate': true,
            'component': MediaFileMain,
        },
        {
            'title': 'Novo material',
            'path': '/materiais/adicionar',
            'exact': true,
            'isPrivate': true,
            'component': MediaFileAdd,
        },
        {
            'title': 'Editar material',
            'path': '/materiais/editar/:id',
            'exact': true,
            'isPrivate': true,
            'component': MediaFileEdit,
        },
    ]
);

//-- Relatórios

addRoutes(
    [
        {
            'title': 'Relatórios',
            'path': '/relatorios',
            'exact': true,
            'isPrivate': true,
            'component': ReportMain,
        }
    ]
);



// - Páginas gerais

addRoutes(
    [
        {
            'title': 'Página não encontrada',
            'path': '*',
            'exact': false,
            'isPrivate': false,
            'component': NotFound,
        },
    ]
);


export const paths = routes;

export default paths;