import React from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import CancelButton from "presentation/components/buttons/cancel";


class CourseLevelForm extends React.Component {
    constructor(props) {
        super(props);

    }
    render() {
        let { loading, form, handleChange, handleSubmit, component } = this.props;
        return (
            <Form>
                <h4>Informações do nível/segmento</h4>
                <div className="form">
                    <Form.Label htmlFor="name">Nome</Form.Label>
                    <Form.Control
                        type="text"
                        id="name"
                        name="name"
                        defaultValue={form.name}
                        onChange={(e) => handleChange(e, component)}
                        aria-describedby="nameHelpBlock"
                    />
                    <Form.Text id="nameHelpBlock" muted>
                        Informe o nome do nível/segmento
                    </Form.Text>
                </div>
                <div className="form mt-2">
                    <Form.Label htmlFor="displayName">Nome de exibição</Form.Label>
                    <Form.Control
                        type="text"
                        id="displayName"
                        name="displayName"
                        defaultValue={form.displayName}
                        onChange={(e) => handleChange(e, component)}
                        aria-describedby="displayNameHelpBlock"
                    />
                    <Form.Text id="displayNameHelpBlock" muted>
                        Escolha um nome de exibição
                    </Form.Text>
                </div>
                <div className="form mt-2">
                    <Form.Label htmlFor="code">Código</Form.Label>
                    <Form.Control
                        type="text"
                        id="code"
                        name="code"
                        defaultValue={form.code}
                        onChange={(e) => handleChange(e, component)}
                        aria-describedby="codeHelpBlock"
                    />
                    <Form.Text id="codeHelpBlock" muted>
                        Informe um código (opicional)
                    </Form.Text>
                </div>
                <div className="mt-3">
                    <CancelButton
                        containerProps={{
                            as: Link,
                            to: `/niveis-segmentos`
                        }}
                    />
                    <Button disabled={loading} variant="success" className="float-end" onClick={() => handleSubmit()}>Salvar</Button>
                </div>
            </Form>
        );
    };
}

export default CourseLevelForm;