import React from 'react';
import { Row, Col } from 'react-bootstrap';
import LoadingIndicator from 'presentation/components/loading-indicator';

function PageLoadingIndicator(props) {

  return (
    <Row justify={"center"}>
      <Col flex="true" span={4} style={{ height: '70vh', display: "flex", justifyContent: "center", alignItems: "center" }} >
        <LoadingIndicator {...props} />
      </Col>
    </Row>
  );
}


export default PageLoadingIndicator;