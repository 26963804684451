import React from 'react';
import { PencilSquare } from 'react-bootstrap-icons';

function EditItemTitle(props) {
  const DEFAULT_TITLE = 'Editar';

  return (
    <><PencilSquare /> {props.title || DEFAULT_TITLE}</>
  );
}


export default EditItemTitle;