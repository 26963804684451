import React from 'react';
import { XLg } from 'react-bootstrap-icons';
import { Button } from "react-bootstrap";

function sanitize(rawProps) {
  const DEFAULT_BUTTON_VARIANT = 'secondary';

  var props = rawProps || {};

  if (!props.variant) {
    props.variant = DEFAULT_BUTTON_VARIANT;
  }


  return props;
}

function CancelButton(props) {
  const { containerProps: rawProps, title } = props;
  const DEFAULT_BUTTON_TITLE = 'Mudei de ideia';

  var buttonProps = sanitize(rawProps);

  return (
    <Button {...buttonProps}><XLg /> {title || DEFAULT_BUTTON_TITLE}</Button>
  );
}


export default CancelButton;