import React from 'react';
import { Trash3 } from 'react-bootstrap-icons';

function RemoveItemTitle(props) {
  const DEFAULT_TITLE = 'Remover';

  return (
    <><Trash3 /> {props.title || DEFAULT_TITLE}</>
  );
}


export default RemoveItemTitle;