import React from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import AddButton from "presentation/components/buttons/add";

import questionOriginsService from "presentation/services/question-origins";
import questionOriginVariationsService from "presentation/services/question-origin-variations";

import RemoveAction from "presentation/pages/questions/elements/remove-action";

import {
    changeQuestionInformationsList,
    loadQuestionInformation,
    handleChangeQuestionInformations,
    questionInformationsFormValue
} from 'presentation/pages/questions/functions/question-informations';


class QuestionInformationsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            localLoading: true,
            localError: false,
            errorMessages: [],
            message: ''
        };

    }

    async componentDidMount() {
        await this.loadData();
        this.mountInformations();
    }

    loadData = async () => {
        let questionOrigins = [];
        let questionOriginVariations = [];
        let localError = false;
        let localLoading = true;
        let errorMessages = [];
        let message = '';

        try {

            await questionOriginsService.findAll().then(async (result) => {
                if (result.error == true) {
                    localError = true;
                    errorMessages.push("Não foi possível recuperar a listagem de origens...");
                } else {
                    if (result.length <= 0) {
                        localError = true;
                        errorMessages.push("Por favor, cadastre origens de questões antes de cadastrar uma questão...");
                    } else {
                        questionOrigins = result;
                        await questionOriginVariationsService.findAll().then(async (originResults) => {
                            if (originResults.error == true) {
                                localError = true;
                                errorMessages.push("Não foi possível recuperar as variações de origem de questão...");
                            } else {
                                if (originResults.length <= 0) {
                                    localError = true;
                                    errorMessages.push("Por favor, cadastre variações de origem de questão, antes de cadastrar uma questão...");
                                } else {
                                    questionOriginVariations = originResults;
                                }
                            }
                        });
                    }
                }
            });

            if (localError === true) {
                localLoading = false;
                message = errorMessages.join(" / ");
            } else {
                localError = false;
                localLoading = false;

            }
            this.setState({ ...this.state, questionOrigins: questionOrigins, questionOriginVariations: questionOriginVariations, localError: localError, localLoading: localLoading, message: message });
        } catch (err) {
            this.setState({
                localLoading: false,
                localError: true,
                message: err.message
            });
        }

    }

    addOption = () => {
        let { component } = this.props;
        let { totalInformations } = component.state;
        component.setState({ ...component.state, totalInformations: totalInformations + 1 }, () => this.mountInformations());
    }

    removeAction = (key) => {
        let { component } = this.props;

        let { totalInformations } = component.state;

        let questionInformations = changeQuestionInformationsList(component, { key: key }, true);

        //reorganiza as keys
        let index = 0;
        questionInformations.map((element, i) => {
            if (!element.isDeleteRequested) {
                element.key = index + 1;
                index++;
            }
            return element;
        });

        component.setState({ ...component.state, totalInformations: totalInformations - 1, questionInformations: questionInformations }, () => this.mountInformations());
    }

    mountInformations = () => {
        let { component } = this.props;

        const { totalInformations, questionInformations } = component.state;


        const deleteRequested = questionInformations.filter(x => x.isDeleteRequested === true);

        let newQuestionInformations = [];

        for (let i = 0; i < totalInformations; i++) {

            let infoKey = i + 1;

            //Default values

            let defaultId = null;
            let defaultQuestionId = null;
            let defaultQuestionOriginVariationId = null;
            let defaultQuestionOriginId = null;

            let infoId = defaultId;
            let infoQuestionId = defaultQuestionId;
            let infoQuestionOriginVariationId = defaultQuestionOriginVariationId;
            let infoQuestionOriginId = defaultQuestionOriginId;

            let questionInformation = loadQuestionInformation(infoKey, component);

            if (questionInformation !== undefined) {

                infoId = questionInformation['id'] || defaultId;
                infoQuestionId = questionInformation['questionId'] || defaultQuestionId;
                infoQuestionOriginVariationId = questionInformation['questionOriginVariationId'] || defaultQuestionOriginVariationId;
                infoQuestionOriginId = questionInformation['questionOriginId'] || defaultQuestionOriginId;
            }

            newQuestionInformations.push({
                id: infoId,
                key: infoKey,
                questionId: infoQuestionId,
                questionOriginVariationId: infoQuestionOriginVariationId,
                questionOriginId: infoQuestionOriginId,
            });
        }

        let finalQuestionInformations = [...newQuestionInformations, ...deleteRequested];

        component.setState({ ...component.state, questionInformations: finalQuestionInformations });
    }

    render() {
        let { component } = this.props;
        const { questionInformations, loading, totalInformations } = component.state;
        const { questionOrigins, questionOriginVariations, localLoading, localError, message } = this.state;

        const { addOption, removeAction } = this;

        const validQuestionInformations = questionInformations.filter(x => !x.isDeleteRequested);

        return (
            <>
                {loading === true || localLoading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                {(localLoading === false && localError === true) && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {
                    loading === false && localLoading === false && (
                        <Container fluid className="question-informations-container system-management">
                            {validQuestionInformations.map((element, index) => {
                                let key = element.key;
                                let questionOriginVariationId = questionInformationsFormValue(key, 'questionOriginVariationId', component);
                                let questionNumber = questionInformationsFormValue(key, 'questionNumber', component);
                                let questionOriginId = questionInformationsFormValue(key, 'questionOriginId', component);

                                const deleteIsDisabled = (totalInformations < 2);

                                let questionVariations = questionOriginVariations.filter(x => x.questionOriginId == questionOriginId);

                                return (
                                    <Container key={`question-information-${key}`} className={`mt-2 question-information-container`}>
                                        <Row>
                                            <Col className="mt-3 mb-3">
                                                <div>
                                                    <Row>
                                                        <Col>
                                                            <RemoveAction
                                                                component={this}
                                                                optionKey={key}
                                                                loading={loading}
                                                                deleteIsDisabled={deleteIsDisabled}
                                                                removeAction={removeAction} />
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="form mt-2">
                                                    <Form.Label htmlFor={`questionOriginId-${key}`}>Origem</Form.Label>
                                                    <Form.Select
                                                        aria-label="Origem da questão"
                                                        defaultValue={questionOriginId}
                                                        id={`questionOriginId-${key}`}
                                                        name={`questionOriginId-${key}`}
                                                        data-field-name="questionOriginId"
                                                        onChange={(event) => handleChangeQuestionInformations(key, event, component)}
                                                        aria-describedby={`questionOriginIdHelpBlock-${key}`}
                                                    >
                                                        <option value="">Selecione uma origem</option>
                                                        {questionOrigins.map((e, i) => { return <option key={e.id} value={e.id}>{e.name}</option> })}
                                                    </Form.Select>
                                                    <Form.Text id={`questionOriginIdHelpBlock-${key}`} muted>
                                                        Defina qual é a origem da questão
                                                    </Form.Text>
                                                </div>
                                                <div className="form mt-2">
                                                    <Form.Label htmlFor={`questionOriginVariationId-${key}`}>Variação</Form.Label>
                                                    <Form.Select
                                                        aria-label="Variação da origem da questão"
                                                        defaultValue={questionOriginVariationId}
                                                        id={`questionOriginVariationId-${key}`}
                                                        name={`questionOriginVariationId-${key}`}
                                                        data-field-name="questionOriginVariationId"
                                                        onChange={(event) => handleChangeQuestionInformations(key, event, component)}
                                                        aria-describedby={`questionOriginVariationIdHelpBlock-${key}`}
                                                    >
                                                        <option value="">Selecione uma variação</option>
                                                        {questionVariations.map((e, i) => { return <option key={e.id} value={e.id}>{e.name}</option> })}
                                                    </Form.Select>
                                                    <Form.Text id={`questionOriginVariationIdHelpBlock-${key}`} muted>
                                                        Defina qual é a variação da origem da questão
                                                    </Form.Text>
                                                </div>
                                                <div className="form mt-2">
                                                    <Form.Label htmlFor={`questionNumber-${key}`}>Número da questão</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        defaultValue={questionNumber}
                                                        id={`questionNumber-${key}`}
                                                        name={`questionNumber-${key}`}
                                                        data-field-name="questionNumber"
                                                        onChange={(event) => handleChangeQuestionInformations(key, event, component)}
                                                        aria-describedby={`questionNumberHelpBlock-${key}`}
                                                    />
                                                    <Form.Text id={`questionNumberHelpBlock-${key}`} muted>
                                                        Informe o número da questão na origem mencionada acima (opicional)
                                                    </Form.Text>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                );
                            })}
                            <div className="text-center">
                                <AddButton
                                    containerProps={{
                                        onClick: addOption,
                                        variant: 'success',
                                        className: 'mt-5 mb-5'

                                    }}
                                    title='Adicionar outra origem'
                                />
                            </div>
                        </Container>
                    )
                }
            </>
        );
    };
}

export default QuestionInformationsList;